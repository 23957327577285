<div class="order-container">
  <label for="orderSelect">Select Number of Orders to append</label>
  <select id="orderSelect" [(ngModel)]="numOfOrders">
    <option
      *ngFor="
        let number of [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30
        ]
      "
      [value]="number"
    >
      {{ number }}
    </option>
  </select>
  <button class="append-btn" (click)="appendOrders()">APPEND ORDERS</button>
</div>
<div class="table-container">
  <table>
    <thead class="header">
      <tr>
        <th>PO Tracking Number</th>
        <th>PSMS Routing</th>
        <th>
          <span class="required-color header-display px-2"> Supplier Code</span>
        </th>
        <th>
          <span class="required-color header-display px-2"> Supplier Name</span>
        </th>
        <th>Kanban No</th>
        <th>
          <span class="required-color header-display px-2"> Part Number</span>
        </th>
        <th>
          <span class="required-color header-display px-2"> Part Name</span>
        </th>
        <th>
          <span class="required-color header-display px-2">
            Order Delivery Date</span
          >
        </th>
        <th>
          <span class="required-color header-display px-2"
            >Order PickUp Date</span
          >
        </th>
        <th>Plant 1 Qty</th>
        <th>Plant 2 Qty</th>
        <th>Plant 3 Qty</th>
        <th>TRIALQTY</th>
        <th>ASSYTRAINQTY</th>
        <th>CFQTY</th>
        <th>PEQTY</th>
        <th>QEQTY</th>
        <th>AXWQTY</th>
        <th>
          <span class="required-color header-display px-2">
            Total Quantity</span
          >
        </th>
        <th>Comment</th>
        <th>Store Address</th>
        <th>Lineside Address</th>
        <th>Life Cycle Code</th>
        <th>Send To SAP Flag</th>
        <th>Pallet Code</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let order of orders; let i = index"
        [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
      >
        <td>
          <input type="text" [(ngModel)]="order.poTrackingNumber" />
        </td>
        <td>
          <input type="text" [(ngModel)]="order.psmsRouting" />
        </td>

        <td>
          <select
            (change)="onSupplierCodeChange($event, i)"
            [(ngModel)]="order.supplierCode"
            [ngClass]="{
              'input-valid': order.supplierCode,
              'required-field': order.supplierCodeError
            }"
            (change)="checkIfFilled()"
          >
            <option
              *ngFor="let supplier of supplierCodes"
              [value]="supplier.supplierCode"
            >
              {{ supplier.supplierCode }}
            </option>
          </select>
        </td>
        <td>
          <input
            type="text"
            (change)="checkIfFilled()"
            [(ngModel)]="order.supplierName"
            [ngClass]="{
              'input-valid': order.supplierName,
              'required-field': order.supplierNameError
            }"
            readonly
          />
        </td>
        <td><input type="text" [(ngModel)]="order.kanbanNo" /></td>
        <td>
          <input
            type="text"
            (change)="checkIfFilled()"
            [(ngModel)]="order.partNumber"
            [ngClass]="{
              'input-valid': order.partNumber,
              'required-field':
                order.partNumberError || order.partNumberLengthError
            }"
          />
        </td>
        <td>
          <input
            type="text"
            (change)="checkIfFilled()"
            maxlength="5"
            [(ngModel)]="order.partName"
            [ngClass]="{
              'input-valid': order.partName,
              'required-field': order.partNameError
            }"
          />
        </td>
        <td>
          <input
            type="text"
            placeholder="MM/dd/yyyy"
            [(ngModel)]="order.orderDeliveryDate"
            [ngClass]="{
              'input-valid': order.orderDeliveryDate,
              'required-field':
                order.orderDeliveryDateError ||
                order.deliveryDateError ||
                order.invalidDeliveryDateError
            }"
          />
        </td>
        <td>
          <input
            type="text"
            placeholder="MM/dd/yyyy"
            [(ngModel)]="order.orderPickupDate"
            [ngClass]="{
              'input-valid': order.orderPickupDate,
              'required-field':
                order.orderPickupDateError ||
                order.pickupDateError ||
                order.invalidPickupDateError
            }"
          />
        </td>
        <td><input type="text" [(ngModel)]="order.plant1Qty" /></td>
        <td><input type="text" [(ngModel)]="order.plant2Qty" /></td>
        <td><input type="text" [(ngModel)]="order.plant3Qty" /></td>
        <td><input type="text" [(ngModel)]="order.trialQty" /></td>
        <td><input type="text" [(ngModel)]="order.assyTrainQty" /></td>
        <td><input type="text" [(ngModel)]="order.cfQty" /></td>
        <td><input type="text" [(ngModel)]="order.peQty" /></td>
        <td><input type="text" [(ngModel)]="order.qeQty" /></td>
        <td><input type="text" [(ngModel)]="order.axwQty" /></td>
        <td>
          <input
            type="text"
            [(ngModel)]="order.totalQuantity"
            [ngClass]="{
              'input-valid': order.totalQuantity,
              'required-field':
                order.totalQuantityError || order.totalQuantityMismatch
            }"
          />
        </td>
        <td><textarea [(ngModel)]="order.comment"></textarea></td>
        <td><input type="text" [(ngModel)]="order.store" /></td>
        <td><input type="text" [(ngModel)]="order.lineSide" /></td>
        <td><input type="text" [(ngModel)]="order.lifeCycleCode" /></td>
        <td><input type="text" [(ngModel)]="order.sendToSapFlag" /></td>
        <td><input type="text" [(ngModel)]="order.palletCode" /></td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="showError" style="color: red; margin-left: 10px">
  Please verify highlighted fields
</div>
<div class="create_footer fixed-bottom">
  <button
    class="cancel me-3 mt-2 roboto-bold cancelbtnalign"
    (click)="cancel()"
  >
    CANCEL
  </button>

  <button class="b2-button add black-button blackbtnalign me-3 mt-2" (click)="onSubmit()">
    SUBMIT
  </button>
</div>
