<app-notification-message></app-notification-message>
<div class="main-container" [style.max-height.px]="viewportHeight">
  <div class="container">
    <h1 class="heading">SELECT DOCUMENT TO MOVE</h1>
    <div class="content">
      <div class="document-list" [style.max-height.px]="viewportHeight">
        <ul>
          <li
            *ngFor="let document of documents; let i = index"
            [id]="'item-' + i"
          >
            <input
              type="checkbox"
              [checked]="selectedDocumentIndex === i"
              (change)="onCheckboxChange(i)"
              id="checkbox-{{ i }}"
            />
            <label for="checkbox-{{ i }}">{{ document.documentTitle }}</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="b3" (click)="moveUp()">MOVE UP</button>
    <button class="b3" (click)="moveDown()">MOVE DOWN</button>
  </div>
  <footer class="footer">
    <button class="cancel-button" (click)="cancel()">CANCEL</button>
    <div class="button-container">
      <button class="b2-button add" (click)="submit()">SUBMIT</button>
    </div>
  </footer>
</div>
