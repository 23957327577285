import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MassEmailService } from 'src/app/services/suppliers/mass-email.service';
import { UploadOrderSupplierService } from 'src/app/services/upload-orders-supplier/upload-orders-supplier.service';
import { MassEmail } from '../../model/mass-email.model';
import { Observable, catchError, forkJoin, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-send-supplier-notification',
  templateUrl: './send-supplier-notification.component.html',
  styleUrls: ['./send-supplier-notification.component.scss'],
})
export class SendSupplierNotificationComponent implements OnInit {
  form!: FormGroup;
  @ViewChild('fileUpload') myInputVariable: ElementRef | undefined;
  enableCC = false;
  enableFrom = false;
  faDelete = faTrash;
  uploadFileName: string[] = [];
  payloadFileKeys: string[] = [];
  fileList: File[] = [];
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;

  constructor(
    public dialogRef: MatDialogRef<SendSupplierNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
    private readonly fb: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly massEmailService: MassEmailService,
    private readonly uploadOrdersService: UploadOrderSupplierService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      from: new FormControl({ value: '', disabled: true }, Validators.required),
      to: new FormControl({ value: '', disabled: true }),
      subject: ['', Validators.required],
      addCC: new FormControl(false),
      addFrom: new FormControl(false),
      cc: new FormControl({ value: '', disabled: true }),
      files: '',
      message: ['', Validators.required],
    });
    this.form.get('addCC')?.valueChanges.subscribe((checked) => {
      if (checked) {
        this.form.get('cc')?.enable();
      } else {
        this.form.get('cc')?.disable();
      }
    });
    this.form.get('addFrom')?.valueChanges.subscribe((checked) => {
      if (checked) {
        this.form.get('from')?.enable();
      } else {
        this.form.get('from')?.disable();
      }
    });
    this.form.patchValue({
      to: [
        ...new Set(
          this.dataStore.selectedRow
            .split(',')
            .map((email: string) => email.trim())
        ),
      ].join(','),
    });
  }

  close() {
    this.dialogRef.close();
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (this.uploadFileName.length >= 5) {
      this.errorMessage = 'Maximum 5 Files Can Be Selected';
      return;
    }
    this.uploadFileName.push(file.name)
    this.payloadFileKeys.push(`massEmail/${file.name}`)
    this.fileList.push(file);
  }

  removeFile(fileName: string) {
    this.uploadFileName = this.uploadFileName.filter(
      (element) => element !== fileName
    );
    this.payloadFileKeys = this.payloadFileKeys.filter(
      (element) => element.split('/')[1] !== fileName
    )
  }

  onSubmit() {
    if (this.form.valid) {
      this.sendNotification();
    } else {
      this.form.markAllAsTouched();
    }
  }

  sendNotification() {
    const loaderVariable = 'send-supplier';
    if (this.fileList.length > 0) {
      this.ngxUiLoaderService.start(loaderVariable);
      const uploadTasks = this.fileList.map((file) => {
        const originalFileName = file.name;
        const fileType = originalFileName.substring(
          originalFileName.lastIndexOf('.') + 1
        );
        const fileName = `massEmail/${originalFileName}`;

        return this.uploadOrdersService.uploadFile(fileName, fileType).pipe(
          switchMap((presignedUrl) => this.uploadToS3(presignedUrl.url, fileName, file)),
          catchError((err) => {
            this.ngxUiLoaderService.start(loaderVariable);
            this.errorMessage = `Error uploading file ${file.name}: ${err}`;
            return of(null);
          })
        );
      });

      forkJoin(uploadTasks).subscribe({
        next: (_responses) => {
          this.sendMailApiCall();
        },
        error: (_err) => {
          this.ngxUiLoaderService.start('Loader-01');
          this.errorMessage = 'Something Went Wrong';
        },
      });
    } else {
      this.ngxUiLoaderService.start(loaderVariable);
      this.sendMailApiCall();
    }
  }

  uploadToS3(presignedUrl: string, fileName: string, file: File) {
    return new Observable((observer) => {
      const fileInput = file;
      const renamedFile = new File([fileInput], fileName, {
        type: fileInput.type,
        lastModified: fileInput.lastModified,
      });
      axios
        .put(presignedUrl, renamedFile)
        .then((response) => {
          observer.next(response.data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  sendMailApiCall() {
    const loaderVariable = 'send-supplier';
    const payload = this.generatePayload();
    payload.files = this.payloadFileKeys;

    this.ngxUiLoaderService.start(loaderVariable);
    this.massEmailService.sendNotification(payload)?.subscribe({
      next: (result: any) => {
        if (result.success) {
          this.dialogRef.close(result.success);
        } else {
          this.errorMessage = result.error;
        }
        this.ngxUiLoaderService.stop(loaderVariable);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop(loaderVariable);
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  generatePayload() {
    const massEmail: MassEmail = {
      selectedSuppId: this.dataStore.selectedSuppId,
      selectedSuppName: this.dataStore.selectedSuppName ?? '',
      emailIdsList: this.dataStore.selectedRow,
      ccEmailIdsList: this.form.value.cc ?? '',
      message: this.form.value.message,
      emailSubject: this.form.value.subject,
      emailFrom: this.form.value.from ?? '',
      loggedInUserMail: this.dataStore.loggedInEmailId,
    };
    return massEmail;
  }
}
