import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
})
export class NotificationMessageComponent implements OnInit {
  @Input()
  successMessage: string | undefined = undefined;
  @Input()
  errorMessage: string | undefined = undefined;

  constructor() {
    // No Work Here
  }

  ngOnInit(): void {
    // No Work Here
  }
  specialInvoke(status: 'S' | 'E', message: string): void {
    if (status === 'S') {
      this.successMessage = message;
    } else this.errorMessage = message;
    setTimeout(() => {
      this.successMessage = undefined;
      this.errorMessage = undefined;
    }, 5000);
  }
  close() {
    this.errorMessage = undefined;
    this.successMessage = undefined;
  }
}
