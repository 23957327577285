import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  Category,
  DeleteMap,
  Document,
  ModifiedtrainingLists,
  TrainingLists,
} from 'src/app/core/model/manage-online-namc';
import { ManageOnlineTrainingService } from 'src/app/services/tmnaNamc/manage-online-training.service';
import { DeleteDocumentComponent } from '../delete-document/delete-document.component';
import { NotificationMessageComponent } from 'src/app/core/notification-message/notification-message.component';

@Component({
  selector: 'app-manage-online-training',
  templateUrl: './manage-online-training.component.html',
  styleUrls: ['./manage-online-training.component.scss'],
})
export class ManageOnlineTrainingComponent implements OnInit {
  @ViewChild(NotificationMessageComponent)
  childComponent!: NotificationMessageComponent;
  documents: ModifiedtrainingLists[] = [];
  category!: string;
  successMessage: string | undefined;
  cargo!: ModifiedtrainingLists | undefined;
  wrongMessage = 'Something went wrong. Please Contact System Adminstrator.';
  signedUrl!: string;

  constructor(
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly router: Router,
    private readonly manageService: ManageOnlineTrainingService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.ngxUiLoaderService.start('loader-01');
    this.manageService.data$?.subscribe((data) => {
      if (data) {
        this.successMessage = data;
      }
    });
    this.category = ['S', 'N'][
      +this.router.url.includes('manage-namc-documents')
    ];
    this.fetchDocuments();
  }

  fetchDocuments() {
    this.manageService.onLoadTraining(this.category).subscribe({
      next: (res: Document) => {
        this.documents = res.trainingList?.map((document: TrainingLists) => {
          return {
            category: res.categoryList.find(
              (element: Category) => document.categoryid === element.categoryid
            )?.categoryname,
            ...document,
            filesizetext: Number(document.filesizetext)
              ? this.convertToRespectiveByteFormat(
                  Number(document.filesizetext)
                )
              : document.filesizetext,
          };
        });
        this.ngxUiLoaderService.stop('loader-01');
      },
      error: (_error: Error) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.childComponent.specialInvoke('E',this.wrongMessage);

      },
    });
  }

  convertToRespectiveByteFormat(value: number) {
    if (value === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(value) / Math.log(k));
    const convertedValue = parseFloat((value / Math.pow(k, i)).toFixed(2));

    return `${convertedValue} ${sizes[i]}`;
  }

  downloadDocument(documentId: string, documentName: string) {
    this.ngxUiLoaderService.start('loader-01');
    this.manageService.downloadDocument(documentId)?.subscribe({
      next: (res: { message?: string; result: boolean }) => {
        this.ngxUiLoaderService.stop('loader-01');
        if (res.message && res.result) {
          this.signedUrl = res.message;
          this.downloadFile(documentName);
        } else {
          this.childComponent.specialInvoke('E','File Not Found, please contact System Administrator');

        }
      },
      error: (_error) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.childComponent.specialInvoke('E','Failed to download document');

      },
    });
  }

  downloadFile(documentName: string) {
    const fileExtension = documentName.split('.').pop()?.toLowerCase();

    if (fileExtension === 'mp4' || fileExtension === 'pdf') {
      try {
        if (fileExtension === 'mp4') {
          const videoTab = window.open();
          if (videoTab) {
            videoTab.document.write(`
              <video controls autoplay style="width: 100%; height: 100%;">
                <source src="${this.signedUrl}" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            `);
            videoTab.document.title = documentName;
          } else {
            this.childComponent.specialInvoke('E','Failed to open new tab');

          }
        } else if (fileExtension === 'pdf') {
          window.open(this.signedUrl, '_blank');
        }
      } catch (_error) {
        this.childComponent.specialInvoke('E','Failed to fetch and convert file to base64');

      }
    } else {
      const link = document.createElement('a');
      link.href = this.signedUrl;
      link.download = documentName;
      link.click();
    }
  }

  editDocument(documentId: string) {
    this.cargo = this.documents.find(
      (element: TrainingLists) => element.docid === documentId
    );
    sessionStorage.setItem(
      'editTrainingMaterialParams',
      JSON.stringify(this.cargo)
    );
    if (this.category === 'N') {
      this.router.navigate(['/tmna-namc/manage-namc-documents/edit']);
    } else {
      this.router.navigate(['/tmna-namc/manage-online-training/edit']);
    }
  }

  addDocument() {
    if (this.category === 'N') {
      this.router.navigate(['/tmna-namc/manage-namc-documents/add']);
    } else {
      this.router.navigate(['/tmna-namc/manage-online-training/add']);
    }
  }

  reorder() {
    if (this.category === 'N') {
      this.router.navigate(['/tmna-namc/manage-namc-documents/re-order']);
    } else {
      this.router.navigate(['/tmna-namc/manage-online-training/re-order']);
    }
  }

  deleteDocument(documentId: string, fileName: string) {
    const dialogData = this.dialog.open(DeleteDocumentComponent, {
      data: documentId,
      width: '35%',
      height: 'auto',
    });
    dialogData?.afterClosed().subscribe((result: { event: string }) => {
      if (result?.event === 'ConfirmEvent') {
        this.ngxUiLoaderService.start('loader-01');
        this.manageService.deleteDocument(documentId, fileName)?.subscribe({
          next: (res: DeleteMap) => {
            if (res.status === true) {
              this.fetchDocuments();
              this.successMessage = `Training Material ${
                this.documents.find(
                  (element: TrainingLists) => element.docid === documentId
                )?.doctitletext
              } Successfully deleted`;
            } else {
              this.ngxUiLoaderService.stop('loader-01');
              this.childComponent.specialInvoke('E',this.wrongMessage);
             
            }
           
            window.scrollTo({ top: 0, behavior: 'smooth' });
          },
          error: (_error: Error) => {
            this.ngxUiLoaderService.stop('loader-01');
            this.childComponent.specialInvoke('E',this.wrongMessage);
             
          },
        });
      }
    });
  }
}
