<header>
  <label for="close" class="close_button" (click)="onNoClick()" (keydown)="onNoClick()"
    >x</label
  >
  <div class="header" style="display: block">
    <h1>Confirm sending</h1>
  </div>
</header>

<div class="body">
  <label for="descriptiveText" class="catlabel">
    {{ descriptiveText }}
    <p style="color: red">{{ warningText }}</p>
  </label>
</div>

<div class="footer">
  <button
    (click)="onNoClick()"
    class="cancel"
    style="display: inline; background: transparent; border: none !important"
  >
    CANCEL
  </button>
  <button
    mat-raised-button
    class="b2-button add submit"
    style="display: inline"
    (click)="saveClick()"
  >
    CONFIRM
  </button>
</div>
