
<div class="d-flex align-items-center justify-content-end mt-2 me-2">
  <button
    class="b2-button add"
    [disabled]="!createEventBtnCheck"
    (click)="createEvent()"
  >
    CREATE NEW EVENT
  </button>
</div>
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<app-grid
  [columnDefs]="columnDefs"
  [displayRowData]="displayRowData"
  [context]="context"
></app-grid>
