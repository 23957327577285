import { Component } from '@angular/core';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { FilterChangedEvent, IFloatingFilterParams } from 'ag-grid-community';

@Component({
  selector: 'app-refresh',
  template: `<fa-icon
    [icon]="refresh"
    (click)="reload()"
    style="cursor:pointer; color:#2688da"
    class="px-3"
    size="lg"
  ></fa-icon>`,
  // styleUrls: ['./refresh.component.scss']
})
export class RefreshComponent implements IFloatingFilterAngularComp {
  private params!: IFloatingFilterParams;
  refresh = faRedo;
  constructor() {
    //No work
  }
  onParentModelChanged(
    _parentModel: any,
    _filterChangedEvent?: FilterChangedEvent
  ): void {}
  // afterGuiAttached?(): void {
  //   //Nothing to implement as of now
  // }

  agInit(params: IFloatingFilterParams): void {
    this.params = params;
  }

  reload() {
    this.params.api.setFilterModel(null);
  }
}
