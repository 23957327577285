<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<h2 class="mx-2 mt-2">Archive Mode</h2>
<h3 class="alerttxt mx-2">
  IF NO FILTER VALUE IS PROVIDED, ALL ARCHIVE RECORDS WILL BE EXPORTED.
</h3>
<form [formGroup]="eventForm" class="form-container">
  <div class="form-group">
    <label for="projectCode">PROJECT CODE</label>
    <input
      id="projectCode"
      maxlength="4"
      formControlName="projectCode"
      type="text"
      class="form-control"
      disableSpecialCharacters
    />
  </div>
  <div class="form-group">
    <label for="eventDescription">EVENT NAME</label>
    <input
      id="eventName"
      maxlength="30"
      formControlName="eventName"
      type="text"
      class="form-control"
      disableSpecialCharacters
    />
  </div>
  <div class="form-group">
    <label for="eventType">PART NUMBER</label>
    <input
      id="partNumber"
      formControlName="partNumber"
      maxlength="12"
      type="text"
      class="form-control"
      disableSpecialCharacters
    />
  </div>
</form>

<footer class="footer">
  <button class="cancel-button ms-2" (click)="cancel()">CANCEL</button>

  <div class="button-container">
    <button
      class="b2-button add"
      (click)="onSubmit()"
    >
    EXPORT ARCHIVE DATA
    </button>
  </div>
</footer>

