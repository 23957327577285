import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss'],
})
export class NoAccessComponent implements OnInit {
  message =
    'You do not have access to TPROMS application. Please contact System Administrator for access / Click on Home to continue using TPROMS application.';

  constructor() {
    // No Work Here
  }

  ngOnInit(): void {
    if (history.state?.access === 'NO') {
      this.message =
        'You do not have access to TPROMS application. Please contact System Administrator for access.';
    }
  }
}
