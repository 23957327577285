import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EditUserRoleRenderer } from 'src/app/core/grid-renderer/edit-user-role-renderer.component';
import { RefreshComponent } from 'src/app/core/grid-renderer/refresh/refresh.component';
import { UserBoxRenderer } from 'src/app/core/grid-renderer/userboxrenderer.component';
import { ManageUser } from 'src/app/core/model/manage-user';
import { AddEditUserService } from 'src/app/services/tmna-namc/add-edit-user.service';
import { ManageUserService } from 'src/app/services/tmna-namc/manage-user.service';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements OnInit {
  columnDefs: ColDef[] = [];
  displayRowData: ManageUser[] = [];
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;

  constructor(
    public router: Router,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly manageUserService: ManageUserService,
    private readonly addEditUserService: AddEditUserService
  ) {}

  ngOnInit(): void {
    const message = this.addEditUserService.getMessage();
    if (message) {
      this.successMessage = message;
      this.settingTimeOut();
      this.addEditUserService.setMessage();
    }
    this.onLoad();
    this.getcolDefs();
  }

  onLoad() {
    this.ngxUiLoaderService.start('manageuser');
    this.manageUserService.onLoad()?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('manageuser');
        this.displayRowData = result;
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('manageuser');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  getcolDefs() {
    this.columnDefs = [
      {
        headerName: 'Action',
        floatingFilterComponent: RefreshComponent,
        cellRenderer: EditUserRoleRenderer,
        floatingFilterComponentParams: { suppressFilterButton: true },
        minWidth: 80,
        maxWidth: 80,
      },
      {
        headerName: 'USER ID',
        field: 'userId',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 170,
      },
      {
        headerName: 'FIRST NAME',
        field: 'firstName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 170,
      },
      {
        headerName: 'LAST NAME',
        field: 'lastName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 170,
      },
      {
        headerName: 'STATUS',
        field: 'userActiveFlag',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
      },
      {
        headerName: 'NAMC/ROLE',
        field: 'roles',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 530,
        cellRenderer: UserBoxRenderer,
        filterValueGetter: (params) => {
          const items = params.data.roles;
          if (items && items.length > 0) {
            return items
              .map(
                (item: { namcDesc: string; roleDesc: string }) =>
                  `${item.namcDesc} ${item.roleDesc}`
              )
              .join(' ');
          }
          return '';
        },
      },
    ];
  }

  routeAddUser() {
    sessionStorage.setItem('operation','add');
    this.router?.navigate(['tmna-namc/manage-users/add']);
  }
  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 50000000);
  }
}
