import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import {
  SaveDocumentInterface,
  Category,
} from 'src/app/core/model/manage-online-namc';
import { ManageOnlineTrainingService } from 'src/app/services/tmnaNamc/manage-online-training.service';
import { UserService } from 'src/app/services/user.service';
import axios from 'axios';
import { NotificationMessageComponent } from 'src/app/core/notification-message/notification-message.component';

@Component({
  selector: 'app-add-online-training',
  templateUrl: './add-online-training.component.html',
  styleUrls: ['./add-online-training.component.scss'],
})
export class AddOnlineTrainingComponent implements OnInit {
  @ViewChild(NotificationMessageComponent)
  childComponent!: NotificationMessageComponent;
  form: FormGroup;
  uploadedFileName!: string | null;
  categoryList!: Category[];
  category!: string;
  userId!: string;
  fileUploadCheck = false;
  isEdit = false;
  oldFileName!: string;
  oldDocumentId!: string;
  uploadedFileType!: string | null;
  uploadedFileSize!: string | null;
  uploadedFileDate!: string | null;
  oldDocId!: string;
  wrongMessage = 'Something went wrong. Please Contact System Adminstrator.';
  fileTypes: { [key: string]: string } = {
    pdf: 'PDF',
    xls: 'EXCEL',
    xlsx: 'EXCEL',
    dot: 'DOT',
    doc: 'DOC',
    docx: 'DOC',
    pptx: 'PPT',
    ppt: 'PPT',
    m4v: 'MP4',
    mp4: 'MP4',
    pps: 'PPT',
  };
  fileAcceptTypes = '';
  signedUrl!: string;
  errorMessage!: string | undefined;
  successMessage!: string | undefined;
  onlineTrainingUrl = '/tmna-namc/manage-online-training';
  namcDocUrl = '/tmna-namc/manage-namc-documents';

  constructor(
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    public readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly manageService: ManageOnlineTrainingService,
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    private readonly datePipe: DatePipe
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      category: ['', Validators.required],
      description: [''],
      file: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.ngxUiLoaderService.start('loader-01');
    this.category = ['S', 'N'][
      +this.router.url.includes('manage-namc-documents')
    ];
    if (this.category === 'N') {
      this.fileAcceptTypes = '.xlsx,.xls,.pdf,.dot,.doc,.docx,.pptx,.ppt,.pps';
    } else {
      this.fileAcceptTypes =
        '.xlsx,.xls,.pdf,.dot,.doc,.docx,.pptx,.ppt,.m4v,.mp4,.pps';
    }
    this.userService.userDetails?.subscribe((value) => {
      if (value !== null) {
        this.userId = value.userId;
      }
    });

    if (this.router.url.includes('/edit')) {
      this.depositEditParams();
      this.isEdit = true;
    }

    this.manageService
      .categoryDropDown(this.category)
      ?.subscribe((res: Category[]) => {
        this.categoryList = res
          .filter((item) => item.categoryname.trim() !== '')
          .sort((a, b) => a.categoryname.localeCompare(b.categoryname));
        this.ngxUiLoaderService.stop('loader-01');
      });
  }

  depositEditParams() {
    const params = JSON.parse(
      sessionStorage.getItem('editTrainingMaterialParams') ?? '{}'
    );
    this.oldDocId = params.docid;
    this.uploadedFileName = params.docname;
    this.uploadedFileType = this.getFileType(this.uploadedFileName);
    this.uploadedFileSize = params.filesizetext;
    this.oldDocumentId = params.docid;
    this.uploadedFileDate = params.updatetimestamp
      ? params.updatetimestamp
      : params.createtimestamp;
    this.oldFileName = params.docname;
    this.form.patchValue({
      title: params.doctitletext,
      category: params.categoryid ? params.categoryid : '',
      description: params?.descriptiontext,
      file: new File([], '', {
        type: '',
      }),
    });
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const extension = file.name
        ?.substring(file.name.lastIndexOf('.'))
        .toLowerCase();
      const fileSize = file.size;
      let maxSize = 20 * 1024 * 1024;
      if (extension === '.mp4' || extension === '.m4v') {
        maxSize = 30 * 1024 * 1024;
      }
      if (this.isValidFileType(extension) && fileSize <= maxSize) {
        this.uploadedFileName = file.name;
        this.uploadedFileType = this.getFileType(this.uploadedFileName);
        this.uploadedFileSize = this.formatFileSize(file.size);
        this.uploadedFileDate = this.datePipe.transform(
          new Date(),
          'MM/dd/yyyy'
        );
        this.form.patchValue({
          file: file,
        });
        this.form.get('file')?.markAsTouched();
        this.fileUploadCheck = true;
      } else {
        this.uploadedFileName = null;
        if (fileSize > maxSize && this.isValidFileType(extension)) {
          this.form.get('file')?.setErrors({ fileTooLarge: true });
        } else {
          this.form.get('file')?.setErrors({ invalidFileType: true });
        }
        this.form.get('file')?.markAsTouched();
        this.fileUploadCheck = false;
      }
    }
  }

  isValidFileType(extension: string): boolean {
    const allowedExtensionsForOnline = [
      '.xlsx',
      '.xls',
      '.pdf',
      '.doc',
      '.docx',
      '.dot',
      '.pptx',
      '.ppt',
      '.m4v',
      '.mp4',
      '.pps',
    ];
    const allowedExtensionsForNAMC = [
      '.xlsx',
      '.xls',
      '.pdf',
      '.doc',
      '.docx',
      '.dot',
      '.pptx',
      '.ppt',
      '.pps',
    ];
    if (this.category === 'S') {
      return allowedExtensionsForOnline.includes(extension);
    } else {
      return allowedExtensionsForNAMC.includes(extension);
    }
  }

  removeFile() {
    this.uploadedFileName = null;
    this.form.patchValue({
      file: null,
    });
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    if (this.form.valid) {
      this.isEdit ? this.EditDocument() : this.addDocument();
    } else {
      this.form.markAllAsTouched();
    }
  }

  cancel() {
    const dialogRef = this.dialog.open(WarningPopupComponent, {
      disableClose: true,
      width: '570px',
      height: '219px',
    });

    dialogRef.afterClosed()?.subscribe((result: string | undefined) => {
      if (result === undefined) {
        return;
      }
      if (result === 'Success' && this.category === 'N') {
        this.router.navigate([this.namcDocUrl]);
      } else if (result === 'Success' && this.category === 'S') {
        this.router.navigate([this.onlineTrainingUrl]);
      }
    });
  }

  async EditDocument() {
    this.ngxUiLoaderService.start('loader-01');
    const payload = {
      file: {
        data: '',
      },
      fileName: this.form.value.file.name,
      fileType: this.form.value.file.name.split('.')[1],
      user: this.userId,
      desc: this.form.value.description ?? null,
      docTitle: this.form.value.title,
      categoryId: this.form.value.category,
      categoryType: this.category,
      fileSize: this.convertToRespectiveByteFormat(
        this.form.value.file.size ?? 0
      ),
      oldFileName: this.oldFileName,
      docId: this.oldDocumentId,
    };

    this.manageService.editTrainingMaterial(payload)?.subscribe({
      next: (result: SaveDocumentInterface) => {
        const fileInput = this.form.value.file;
        const renamedFile = new File([fileInput], result.filename, {
          type: fileInput.type,
          lastModified: fileInput.lastModified,
        });

        axios
          .put(result.preSigner.url, renamedFile)
          .then((_response) => {
            this.manageService.updateData(
              `Training Material ${payload.docTitle} Updated Successfully.`
            );
            if (this.category === 'N') {
              this.router.navigate([this.namcDocUrl]);
            } else if (this.category === 'S') {
              this.router.navigate([this.onlineTrainingUrl]);
            }
          })
          .catch((_error) => {
            this.ngxUiLoaderService.stop('loader-01');
            this.childComponent.specialInvoke('E',this.wrongMessage);

          });
      },
      error: (_err) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.childComponent.specialInvoke('E', 'Something went wrong. Please Contact System Administrator.');

      },
    });
  }

  async addDocument() {
    this.ngxUiLoaderService.start('loader-01');
    const payload = {
      file: {
        data: '',
      },
      fileName: this.form.value.file.name,
      fileType: this.form.value.file.name.split('.')[1],
      user: this.userId,
      desc: this.form.value.description ?? null,
      docTitle: this.form.value.title,
      categoryId: this.form.value.category,
      categoryType: this.category,
      fileSize: this.convertToRespectiveByteFormat(this.form.value.file.size),
    };

    this.manageService.addTrainingMaterial(payload)?.subscribe({
      next: (result: SaveDocumentInterface) => {
        const fileInput = this.form.value.file;
        const renamedFile = new File([fileInput], result.filename, {
          type: fileInput.type,
          lastModified: fileInput.lastModified,
        });

        axios
          .put(result.preSigner.url, renamedFile)
          .then((_response: any) => {
            this.manageService.updateData(
              `New Training Material ${payload.docTitle} Added Successfully.`
            );
            if (this.category === 'N') {
              this.router.navigate([this.namcDocUrl]);
            } else if (this.category === 'S') {
              this.router.navigate([this.onlineTrainingUrl]);
            }
          })
          .catch((_error: any) => {
            this.ngxUiLoaderService.stop('loader-01');
            this.childComponent.specialInvoke('E',this.wrongMessage);

          });
      },
      error: (_err) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.childComponent.specialInvoke('E',this.wrongMessage);

      },
    });
  }

  convertToRespectiveByteFormat(value: number) {
    if (value === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(value) / Math.log(k));
    const convertedValue = parseFloat((value / Math.pow(k, i)).toFixed(2));

    return `${convertedValue} ${sizes[i]}`;
  }

  downloadDocumentForAdd() {
    const blob = new Blob([this.form.controls['file'].value], {
      type: this.form.controls['file'].value.type,
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = this.form.controls['file'].value.name;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  downloadDocumentForEdit(documentId: string, documentName: string | null) {
    this.manageService.downloadDocument(documentId).subscribe((res: any) => {
      this.signedUrl = res.message;
      this.downloadFile(documentName);
    });
  }

  downloadFile(documentName: string | null) {
    const link = document.createElement('a');
    link.href = this.signedUrl;
    link.download = documentName ?? '';
    link.click();
  }

  callRespectiveDownlaod() {
    if (this.isEdit) {
      if (this.form.value.file.size) {
        this.downloadDocumentForAdd();
      } else {
        this.downloadDocumentForEdit(this.oldDocId, this.uploadedFileName);
      }
    } else {
      this.downloadDocumentForAdd();
    }
  }

  formatFileSize(sizeInBytes: number): string {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let size = sizeInBytes;
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }
    return `${size.toFixed(2)} ${units[unitIndex]}`;
  }

  getFileType(fileName: string | null): string {
    const fileExtension =
      fileName?.substring(fileName.lastIndexOf('.') + 1)?.toLowerCase() ?? '';
    return this.fileTypes[fileExtension] || 'Unknown Format';
  }
}
