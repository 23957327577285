
<div class="popup-container">
    <button class="close black-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  
    <h2 class="p-3 mb-0 roboto-medium">Supplier Ship Date</h2>
    <hr id="shadow" />
    <br />
    <div class="dialog-body">
        <p>Are you sure you want to update Supplier Ship Date for the
            selected order(s)?</p>
            <input type="date" value="mm/dd/yyyy" />
         <br>
         <br>
      <p>NAMC Comments</p>
      <textarea placeholder=""></textarea>
    </div>
    <div  class="dialog-actions">
      <button mat-button class="b2-button add yes-button" (click)="onYesClick()">SAVE</button>
      <button mat-button class="no-button" (click)="onNoClick()">CANCEL</button>
    </div>
  </div>