import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import axios from 'axios';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Events } from 'src/app/core/model/events.model';
import { UserResponse } from 'src/app/core/model/user.model';
import { UploadOrdersService } from 'src/app/services/events-orders/upload-orders.service';
import { UploadOrderSupplierService } from 'src/app/services/upload-orders-supplier/upload-orders-supplier.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-upload-orders-namc',
  templateUrl: './upload-orders-namc.component.html',
  styleUrls: ['./upload-orders-namc.component.scss'],
})
export class UploadOrdersNamcComponent implements OnInit {
  eventForm!: FormGroup;
  uploadedFileName: string | null = null;
  events: Events[] = [];
  hideForm = false;
  errorMessage: string | undefined = undefined;
  successMessage: string | undefined = undefined;
  selectDrop = '';
  userId!: string;
  validationMessages: string[] = [];
  eventType: string | undefined;
  uploadOptions = [
    { id: '1', name: 'Upload Order' },
    { id: '2', name: 'Upload Updated Order' },
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly uploadOrdersService: UploadOrdersService,
    private readonly userService: UserService,
    private readonly userServiceSupp: UploadOrderSupplierService
  ) {}

  ngOnInit(): void {
    this.getUserDetails();
    this.eventForm = this.fb.group({
      event: ['', Validators.required],
      eventDescription: [{ value: '', disabled: true }],
      eventType: [{ value: '', disabled: true }],
      projectCode: [{ value: '', disabled: true }],
      file: [null, Validators.required],
    });
  }

  summonEvents() {
    this.ngxUiLoaderService.start('loader-01');
    this.uploadOrdersService.summonEvents()?.subscribe({
      next: (result: Events[]) => {
        this.ngxUiLoaderService.stop('loader-01');
        if (result) {
          this.events = result;
        } else {
          this.setErrorMessage(`No Events Available`);
        }
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('loader-01');
        const error = err as HttpErrorResponse;
        this.setErrorMessage(error.error.error);
      },
    });
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const validMimeTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      if (validMimeTypes.includes(file.type)) {
        if (file) {
          this.uploadedFileName = file.name;
          this.eventForm.patchValue({
            file: file,
          });
        }
      } else {
        this.setErrorMessage(
          `Not A Valid File To Process, Only Upload Valid Excel Sheets`
        );
      }
    }
  }

  removeFile() {
    this.uploadedFileName = null;
    this.eventForm.patchValue({
      file: null,
    });
  }

  showForm(event: any) {
    if (event.value === '') {
      this.hideForm = false;
    } else {
      this.hideForm = true;
    }

    this.summonEvents();
    this.eventForm.patchValue({
      file: null,
      event: '',
      eventDescription: '',
      eventType: '',
      projectCode: null,
    });
    this.eventForm.markAsUntouched();
  }

  setDetails() {
    this.eventForm.patchValue({
      eventDescription: this.events?.find(
        (element: Events) =>
          element.eventId === Number(this.eventForm.value.event)
      )?.eventDescription,
    });
    this.eventForm.patchValue({
      eventType: this.events.find(
        (element: Events) =>
          element.eventId === Number(this.eventForm.value.event)
      )?.orderType,
    });
    this.eventForm.patchValue({
      projectCode: this.events.find(
        (element: Events) =>
          element.eventId === Number(this.eventForm.value.event)
      )?.projectCode,
    });
  }

  onSubmit(): void {
    if (this.eventForm?.valid) {
      this.selectDrop === '1'
        ? this.submitOrders()
        : this.submitUpdatedOrders();
    } else {
      this.eventForm.markAllAsTouched();
    }
  }

  submitUpdatedOrders() {
    this.eventType = this.events.find(
      (element: Events) => element.eventId === this.eventForm.value.event
    )?.orderType;
    this.ngxUiLoaderService.start('loader-01');
    const originalFileName = this.eventForm.value.file?.name;
    const fileType = originalFileName?.substring(
      originalFileName.lastIndexOf('.') + 1
    );
    const fileName = `UploadOrders/${originalFileName}`;

    this.userServiceSupp.uploadFile(fileName, fileType).subscribe({
      next: (response) => {
        const fileInput = this.eventForm.value.file;
        const renamedFile = new File([fileInput], response.filename, {
          type: fileInput.type,
          lastModified: fileInput.lastModified,
        });

        axios.put(response.url, renamedFile).then((_response) => {
          this.uploadOrdersService
            .submitUpdatedOrders(
              this.eventForm?.value.event,
              this.eventType,
              this.userId
            )
            ?.subscribe({
              next: (result) => {
                this.ngxUiLoaderService.stop('loader-01');
                if (result.result === 'Success') {
                  this.setSuccessMessage('FILE UPLOADED SUCCESSFULLY');
                } else {
                  this.setErrorMessage(result.result);
                }
              },
              error: (err) => {
                this.validationMessages.push(
                  ...(err.error.errorList || 'Something Went Wrong')
                );
                setTimeout(() => {
                  this.validationMessages.length = 0;
                }, 5000);
                this.ngxUiLoaderService.stop('loader-01');
                const error = err as HttpErrorResponse;
                this.setErrorMessage(error.error.error);
              },
            });
        });
      },
    });
  }

  getUserDetails() {
    this.userService.getUserDetails()?.subscribe((response: UserResponse) => {
      this.userId = response.userDetails.userId;
    });
  }

  submitOrders() {
    this.ngxUiLoaderService.start('loader-01');

    const originalFileName = this.eventForm.value.file.name;
    const fileType = originalFileName.substring(
      originalFileName.lastIndexOf('.') + 1
    );
    const fileName = `UploadOrders/${originalFileName}`;

    this.userServiceSupp.uploadFile(fileName, fileType).subscribe({
      next: (response) => {
        const fileInput = this.eventForm.value.file;
        const renamedFile = new File([fileInput], response.filename, {
          type: fileInput.type,
          lastModified: fileInput.lastModified,
        });

        axios.put(response.url, renamedFile).then((_response) => {
          const payloadObj = {
            trialEventId: Number(this.eventForm?.value.event),
            userId: this.userId,
            fileName: fileName,
          };

          this.uploadOrdersService.submitOrders(payloadObj)?.subscribe({
            next: (result: { result: string }) => {
              this.ngxUiLoaderService.stop('loader-01');
              if (result.result === 'Success') {
                this.setSuccessMessage('FILE UPLOADED SUCCESSFULLY');
              } else {
                this.setErrorMessage('Something Went wrong');
              }
            },
            error: (err) => {
              this.ngxUiLoaderService.stop('loader-01');
              const error = err as HttpErrorResponse;
              this.setErrorMessage(error.error.error);
            },
          });
        });
      },
    });
  }

  setErrorMessage(error: string) {
    this.errorMessage = error;
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }

  setSuccessMessage(success: string) {
    this.successMessage = success;
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
}
