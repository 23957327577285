import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import { SelectedRole } from 'src/app/core/model/role-check.model';
import {
  AppendOrderModel,
  EventList,
  TrialOrders,
} from 'src/app/core/model/trial-order.model';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class CreateTrailsService {
  baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }
  getEventList(roleDetails: SelectedRole) {
    return this.http
      .get<EventList[]>(
        `${
          this.baseApiUrl
        }/events-orders/trial-order/events?roleDetails=${JSON.stringify(
          roleDetails
        )}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  getQuantityNames(eventId: string) {
    return this.http
      .get<AppendOrderModel>(
        `${this.baseApiUrl}/events-orders/orders/onload?eventid=${eventId}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  cancelTrails(orders: any) {
    return this.http
      .post(`${this.baseApiUrl}/events-orders/orders/cancel`, orders, {
        withCredentials: true,
        responseType: 'text', // Change response type to 'text' instead of 'json'
      })

      .pipe(catchError(handleError));
  }

  oncreateTrails(payload: any) {
    return this.http
      .post<TrialOrders>(`${this.baseApiUrl}/events-orders/orders`, payload, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }
  submitOrder(order: any) {
    return this.http
      .post<TrialOrders>(
        `${this.baseApiUrl}/events-orders/orders/appendorders`,
        order,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }
  editOrder(payload: any) {
    return this.http
      .post<TrialOrders>(
        `${this.baseApiUrl}/events-orders/orders/editorder`,
        payload,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }
}
