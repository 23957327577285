import { Component } from '@angular/core';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-custom-cell-editor',
  template: `
    <div class="custom-editor" [ngStyle]="{ width: width + 'px' }">
      <input [(ngModel)]="value" class="input-field" />
      <span class="edit-icon" (click)="saveButton()">
        <fa-icon
          [icon]="faCheck"
          title="save"
          class="d-flex justify-content-center"
        ></fa-icon
      ></span>
      <span class="cancel-icon" (click)="cancelEdit()">
        <fa-icon
          [icon]="faXmark"
          title="cancel"
          class="d-flex justify-content-center"
        ></fa-icon>
      </span>
    </div>
  `,
  styles: [
    `
      .custom-editor {
        display: flex;
        align-items: center;
        position: relative;
      }
      .input-field {
        flex: 1;
        padding-right: 35px; /* Space for icons */
        width: 100%; /* Ensure it fills the container */
      }
      .edit-icon,
      .cancel-icon {
        position: absolute;
        right: 30px;
        cursor: pointer;
        color: black;
        font-weight:bold
      }
      .cancel-icon {
        right: 5px;
      }
    `,
  ],
})
export class CustomCellEditorComponent implements ICellEditorAngularComp {
  params!: ICellEditorParams;
  faCheck = faCheck;
  faXmark = faXmark;
  value: string = '';
  width: number = 150;
  originalValue: string = '';

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.originalValue = params.value;

    setTimeout(() => {
      if (params.eGridCell) {
        this.width = params.eGridCell.offsetWidth;
      }
    });
  }

  saveButton() {
    this.params.stopEditing();
    console.log('the data has been saved');
  }

  getValue(): any {
    return this.value;
  }

  cancelEdit(): void {
    this.value = this.originalValue;
    this.params.stopEditing();
  }
}
