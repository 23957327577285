import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { lastValueFrom } from 'rxjs';
import { SelectedRole } from 'src/app/core/model/role-check.model';
import {
  EventList,
  HeaderDetails,
  OrderStatusTemplateDetails,
} from 'src/app/core/model/trial-order.model';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import * as XLSX from 'xlsx-js-style';

@Component({
  selector: 'app-trial-orders',
  templateUrl: './trial-orders.component.html',
  styleUrls: ['./trial-orders.component.scss'],
})
export class TrialOrdersComponent implements OnInit {
  requiredDisplayCheck = true;
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  eventList: EventList[] = [];
  isBtnEnable = false;
  showError = false;
  eventId = '';
  projectCode = '';
  eventTypeId = 0;
  selectedRole: SelectedRole = {
    role: undefined,
    namc: undefined,
    supplierCode: undefined,
    childSupplierCode: undefined,
  };
  allSelection = false;
  deletedEvent = false;
  blankOrderHeaders: string[] = [];
  blankOrderComments: string[] = [];
  orderStatusHeaders: string[] = [];
  orderStatusComments: string[] = [];
  orderStatusData: OrderStatusTemplateDetails[] = [];

  constructor(
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly trialOrderervice: TrialOrdersService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.onLoadEventList();
    this.selectedRole = JSON.parse(
      sessionStorage.getItem('roleDetails') ?? '{}'
    );
  }

  eventDropdownChange(event: any) {
    const eventStatus = this.eventList
      .find((value) => value.eventId === event.value)
      ?.eventStatus?.toString();
    this.showError = false;
    this.eventId = event.value;
    sessionStorage.setItem('eventId', this.eventId);
    if (event.value === 0) {
      this.isBtnEnable = false;
      this.deletedEvent = false;
      this.allSelection = true;
    } else if (eventStatus === '2') {
      this.allSelection = false;
      this.isBtnEnable = false;
      this.deletedEvent = true;
    } else {
      this.allSelection = false;
      this.deletedEvent = false;
      this.isBtnEnable = true;
    }
  }

  onLoadEventList() {
    const roleDetails = JSON.parse(
      sessionStorage.getItem('roleDetails') ?? '{}'
    ) as SelectedRole;
    this.ngxUiLoaderService.start('eventList');
    this.trialOrderervice.getEventList(roleDetails)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('eventList');
        this.eventList = result;
        this.eventList.unshift({
          eventId: 0,
          eventName: 'All',
          eventStatus: 0,
        });
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('eventList');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  displayTrialOrder() {
    if (
      this.isBtnEnable === false &&
      this.allSelection === false &&
      this.deletedEvent === false
    ) {
      this.showError = true;
    } else if (this.selectedRole.role === 'namc') {
      this.router.navigate(['event-order/trial-orders/trialOrderList'], {
        state: { myData: sessionStorage.getItem('eventId') },
      });
    } else {
      this.router.navigate(
        ['event-order/trial-orders/supplierTrialOrderList'],
        {
          state: { myData: sessionStorage.getItem('eventId') },
        }
      );
    }
  }

  displayHistory() {
    this.router.navigate(['event-order/trial-orders/trialOrderHistory'], {
      state: { myData: this.eventId },
    });
  }

  displayShippingAddress() {
    this.router.navigate(['event-order/trial-orders/shippingAddress'], {
      state: { myData: this.eventId },
    });
  }

  downloadOrderStatus() {
    this.ngxUiLoaderService.start('downloadOrderStatus');
    this.trialOrderervice.getOrderStatusTemplateData(this.eventId)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('downloadOrderStatus');
        this.orderStatusComments = result.headerComments;
        this.projectCode = result.projectCode;
        this.eventTypeId = result.eventTypeId;
        const headers: HeaderDetails[] = result.orderHeaders.map(
          (value, index) => {
            return { name: value, comments: this.orderStatusComments[index] };
          }
        );
        this.generateExcel(
          headers,
          result.fileContent,
          this.projectCode,
          this.eventTypeId
        );
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('downloadOrderStatus');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  downloadBlankOrder() {
    this.ngxUiLoaderService.start('downloadBlankOrder');
    this.trialOrderervice.getBlankOrderTemplateData(this.eventId)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('downloadBlankOrder');
        this.projectCode = result.projectCode;
        this.eventTypeId = result.eventTypeId;
        this.blankOrderComments = result.headerComments;
        this.orderStatusData = [];
        const headers: HeaderDetails[] = result.orderHeaders.map(
          (value, index) => {
            return { name: value, comments: this.blankOrderComments[index] };
          }
        );
        this.generateExcel(
          headers,
          this.orderStatusData,
          this.projectCode,
          this.eventTypeId
        );
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('downloadBlankOrder');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  async generateExcel(
    orderHeaders: HeaderDetails[],
    orderStatusData: OrderStatusTemplateDetails[],
    projectCode: string,
    eventTypeId: number
  ) {
    let wb: XLSX.WorkBook;
    if (orderStatusData.length === 0) {
      wb = await this.readExcel('./assets/files/Blank_Order_Template.xlsx');
    } else {
      wb = XLSX.utils.book_new();
    }
    const headers = orderHeaders.map((value) => {
      const comment: XLSX.Comments = [{ t: value.comments, T: false }];
      comment.hidden = true;
      const cell: XLSX.CellObject = {
        v: value.name,
        t: 's',
        c: comment,
        s: { font: { bold: true } },
      };
      return cell;
    });
    const newSheetData: XLSX.CellObject[][] = [headers];
    if (orderStatusData.length === 0) {
      this.formulaGeneration(orderHeaders, newSheetData);
    } else {
      this.orderStatusDataMapping(orderStatusData, orderHeaders, newSheetData);
    }
    const ws = XLSX.utils.aoa_to_sheet(newSheetData);
    this.columnWidthGeneration(headers, ws);
    this.sheetCreationInExcel(
      orderStatusData,
      ws,
      wb,
      projectCode,
      eventTypeId
    );
  }

  async readExcel(filePath: string) {
    const excel = await lastValueFrom(
      this.trialOrderervice.readExcel(filePath)
    );
    return XLSX.read(excel, {
      cellNF: true,
      cellStyles: true,
      cellDates: true,
    });
  }

  indexToColumnLetter(index: number): string {
    let letter = '';
    while (index >= 0) {
      letter = String.fromCharCode((index % 26) + 65) + letter;
      index = Math.floor(index / 26) - 1;
    }
    return letter;
  }

  formulaGeneration(
    orderHeaders: HeaderDetails[],
    newSheetData: XLSX.CellObject[][]
  ) {
    const formulaStart = this.indexToColumnLetter(
      orderHeaders.findIndex(
        (value: HeaderDetails) => value.name === 'PLANT 3 QTY'
      ) + 1
    );
    const formulaEnd = this.indexToColumnLetter(
      orderHeaders.findIndex(
        (value: HeaderDetails) => value.name === 'TOTAL QTY'
      ) - 1
    );
    const formulaField = orderHeaders.findIndex(
      (value: HeaderDetails) => value.name === 'TOTAL QTY'
    );
    const startRow = 2;
    const endRow = 4000;
    for (let row = startRow; row <= endRow; row++) {
      const newRow = Array(orderHeaders.length).fill({});
      const cell: XLSX.CellObject = {
        t: 'n',
        f: `SUM(${formulaStart}${row}:${formulaEnd}${row})`,
      };
      newRow[formulaField] = cell;
      newSheetData.push(newRow);
    }
  }

  columnWidthGeneration(headers: XLSX.CellObject[], ws: XLSX.WorkSheet) {
    const wideWidth = 65;
    const commonWidth = 15;
    const specificWidth = 25;
    const wideColumns = ['NAMC COMMENT', 'PART NAME'];
    const specificColumns = [
      'EVENT NAME',
      'SPECIALIST EMAIL ADDRESS',
      'WORK ORDER NUMBER',
    ];
    ws['!cols'] = headers.map((header) => {
      const headerName = header.v?.toString() ?? '';
      if (wideColumns.includes(headerName)) {
        return { wch: wideWidth };
      } else if (specificColumns.includes(headerName)) {
        return { wch: specificWidth };
      } else {
        return { wch: commonWidth };
      }
    });
  }

  sheetCreationInExcel(
    orderStatusData: OrderStatusTemplateDetails[],
    ws: XLSX.WorkSheet,
    wb: XLSX.WorkBook,
    projectCode: string,
    eventTypeId: number
  ) {
    if (orderStatusData.length !== 0) {
      XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    } else {
      XLSX.utils.book_append_sheet(wb, ws, 'Orders');
      wb.SheetNames = wb.SheetNames.splice(0, 1);
      wb.SheetNames.unshift('Orders');
    }
    XLSX.writeFile(
      wb,
      this.generateFileName(projectCode, eventTypeId) + '.xlsx'
    );
  }

  orderStatusDataMapping(
    orderStatusData: OrderStatusTemplateDetails[],
    orderHeaders: HeaderDetails[],
    newSheetData: XLSX.CellObject[][]
  ) {
    const dynamicHeaders: string[] = [];
    if (orderStatusData.length > 0 && orderStatusData[0].trialOrderQuantity) {
      orderStatusData[0].trialOrderQuantity.forEach((q) => {
        dynamicHeaders.push(q.quantityname);
      });
      orderStatusData.forEach((order) => {
        const row = orderHeaders.map((header) => {
          if (dynamicHeaders.includes(header.name)) {
            const quantityEntry = order.trialOrderQuantity?.find(
              (q) => q.quantityname === header.name
            );
            return {
              v: quantityEntry ? quantityEntry.orderQuantity : '',
              t: 's',
            };
          } else {
            const field = this.getFieldForHeader(header.name);
            return { v: this.extractValue(order, field) || '', t: 's' };
          }
        });
        newSheetData.push(row as XLSX.CellObject[]);
      });
    }
  }

  getFieldForHeader(header: string) {
    const headerFieldMap: Record<string, string> = {
      'ORDER STATUS': 'orderStatus',
      'ORDER TRACKING NO': 'orderNumber',
      'EVENT NAME': 'eventName',
      'ORDER TYPE': 'orderType',
      'PSMS ROUTING': 'psmsRouting',
      'SPECIALIST EMAIL ADDRESS': 'emailId',
      'KANBAN NO': 'kanbanNumber',
      'PART NO': 'partNumber',
      'PART NAME': 'partName',
      'NAMC DUE DATE': 'orderDueDate',
      'SUPPLIER PICKUP DATE': 'supplierPickupDate',
      'PO TRACKING NUMBER': 'poTrackingNumber',
      'PLANT 1 QTY': 'plant1Qty',
      'PLANT 2 QTY': 'plant2Qty',
      'PLANT 3 QTY': 'plant3Qty',
      'TOTAL QTY': 'totalQty',
      'NAMC COMMENT': 'namcComment',
      'SUPPLIER CODE': 'supplierCode',
      'SUPPLIER NAME': 'supplierName',
      'STORES ADDRESS': 'storeAddress',
      'LINE SIDE ADDRESS': 'lineSideAddress',
      'LIFE CYCLE CODE': 'lifeCycleCode',
      'WORK ORDER NUMBER': 'workOrderNumber',
      'SEND TO SAP FLAG': 'sendToSapFlag',
      'PO LINE NUMBER': 'poLineNumber',
      'OWK LABEL LINE1': 'owkLabelLine1',
      'OWK LABEL LINE2': 'owkLabelLine2',
      'PALLET CODE': 'palletCode',
    };
    return headerFieldMap[header] || '';
  }
  extractValue(order: OrderStatusTemplateDetails, field: string) {
    return order[field as keyof OrderStatusTemplateDetails] || '';
  }

  getCurrentDate(): Date {
    return new Date();
  }

  generateFileName(projectCode: string, eventTypeId: number) {
    const now = this.getCurrentDate();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const formattedDate = `${month}${day}${year}`;
    const hours = String((now.getHours() + 24) % 12 || 12).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}${minutes}${seconds}`;
    if (eventTypeId === 1) {
      return `Manifest_Order_${projectCode}_${formattedDate}_${formattedTime}`;
    } else {
      return `Purchase_Order_${projectCode}_${formattedDate}_${formattedTime}`;
    }
  }
}
