import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faWeibo } from '@fortawesome/free-brands-svg-icons';
import {
  faCalendar,
  faCheckSquare,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/free-regular-svg-icons';
import {
  faColumns,
  faComment,
  faEnvelope,
  faEye,
  faFilter,
  faList,
  faPlus,
  faShareFromSquare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CancelTrailsComponent } from 'src/app/core/dialogs/cancel-trails/cancel-trails.component';
import { AcceptOrdersDialogComponent } from 'src/app/core/dialogs/event-and-orders/accept-orders-dialog/accept-orders-dialog.component';
import { ShipChangeRequestComponent } from 'src/app/core/dialogs/event-and-orders/ship-change-request/ship-change-request.component';
import { SupplierMailComponent } from 'src/app/core/dialogs/event-and-orders/supplier-mail/supplier-mail.component';
import { SupplierShipDateComponent } from 'src/app/core/dialogs/event-and-orders/supplier-ship-date/supplier-ship-date.component';
import { FiltersComponent } from 'src/app/core/dialogs/filter/filter.component';
import { NamcCommentsActionComponent } from 'src/app/core/dialogs/namc-comments-action/namc-comments-action.component';
import { SapInterfaceComponent } from 'src/app/core/dialogs/sap-interface/sap-interface.component';
import { NamcCommentsActionList } from 'src/app/core/model/comments.model';
import { SelectedRole } from 'src/app/core/model/role-check.model';
import {
  AttributeDetails,
  TrialOrderDetails,
} from 'src/app/core/model/trial-order.model';
import { User } from 'src/app/core/model/user.model';
import { ExportOrdersService } from 'src/app/services/event-and-orders/export-orders.service';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';

@Component({
  selector: 'app-namc-trial-order-buttons',
  templateUrl: './namc-trial-order-buttons.component.html',
  styleUrls: ['./namc-trial-order-buttons.component.scss'],
})
export class NamcTrialOrderButtonsComponent {
  customizedSuccessMessage: string[] = [];
  customizederrorMessage: string[] = [];
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  faComment = faComment;
  faCheckSquareO = faCheckSquare;
  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;
  faEye = faEye;
  faPlus = faPlus;
  faList = faList;
  faTrash = faTrash;
  faEnvelope = faEnvelope;
  faShareFromSquare = faShareFromSquare;
  faCalendar = faCalendar;
  faWeibo = faWeibo;
  faFilter = faFilter;
  faColumns = faColumns;
  user: User | null = null;
  @Input() currentRole = '';
  @Input() eventId = '';
  @Input() selectedRows: TrialOrderDetails[] = [];
  @Output() buttonClicked = new EventEmitter<string>();
  @Input() eventDetailsFlags: AttributeDetails = {
    trialEventName: '',
    displayPOTrackingNumber: '',
    showEdit: '',
    isAllEvents: '',
    isEventDeleted: '',
  };
  orderId!: string;
  @Output() namcCommentEmit: EventEmitter<NamcCommentsActionList> =
    new EventEmitter<NamcCommentsActionList>();
  @Input() selectedOrder: TrialOrderDetails[] = [];
  limit = 4200;
  @Input() rowCount!: number;
  trialOrderDetails: TrialOrderDetails[] = [];
  start = 1;
  errorMessageHeader: string | undefined = undefined;

  constructor(
    private readonly exportService: ExportOrdersService,
    private readonly trialOrderService: TrialOrdersService,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  onButtonClick(action: string) {
    this.buttonClicked.emit(action);
  }

  appendTrails() {
    this.router.navigate(
      ['event-order/trial-orders/trialOrderList/appendTrails'],
      {
        state: { myData: sessionStorage.getItem('eventId') },
      }
    );
  }

  createTrails() {
    this.router.navigate(
      ['event-order/trial-orders/trialOrderList/createTrails'],
      {
        state: { myData: sessionStorage.getItem('eventId') },
      }
    );
  }

  openReviewShipDate() {
    if (this.selectedRows.length === 1) {
      const data = JSON.stringify(this.selectedRows[0]);
      this.router.navigate(['event-order/trial-orders/confirmOrder'], {
        state: { data: data },
      });
    } else if (this.selectedRows.length === 0 || !this.selectedRows) {
      const err = 'YOU NEED TO SELECT EXACTLY ONE ORDER';
      const currentPath = this.router.routerState.snapshot.url;

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentPath], {
          state: {
            err,
            myData: sessionStorage.getItem('eventId'),
            dummy: true,
          },
        });
      });
    } else {
      const err = 'YOU NEED TO SELECT ONE ORDER FOR THE SELECTED OPERATION';
      const currentPath = this.router.routerState.snapshot.url;

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentPath], {
          state: {
            err,
            myData: sessionStorage.getItem('eventId'),
            dummy: true,
          },
        });
      });
    }
  }

  openAcceptShipDateDialog() {
    const dialogRef = this.dialog.open(AcceptOrdersDialogComponent, {
      width: '400px',
      data: {
        headerTitle: 'Accept',
        selectedOrder: this.selectedOrder,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.namcCommentEmit.emit({ message: '', success: true });
      this.customizederrorMessage =
        Object.keys(result).length > 1 ? result.error : Object.values(result);
      this.customizedSuccessMessage = result.success ?? [];
      this.settingTimeOut();
    });
  }

  openRejectShipDateDialog(): void {
    const dialogRef = this.dialog.open(AcceptOrdersDialogComponent, {
      width: '400px',
      data: {
        headerTitle: 'Reject',
        selectedOrder: this.selectedOrder,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.namcCommentEmit.emit({ message: '', success: true });
      this.customizederrorMessage =
        Object.keys(result).length > 1 ? result.error : Object.values(result);
      this.customizedSuccessMessage = result.success ?? [];
      this.settingTimeOut();
    });
  }

  namcCommentsValidation() {
    if (this.selectedOrder.length === 0) {
      this.errorMessage = 'Please select at least one order.';
      this.settingTimeOut();
    } else if (this.selectedOrder.length > 1) {
      this.errorMessage = 'Please select only one order';
      this.settingTimeOut();
    } else if (
      this.selectedOrder[0].status.toLowerCase() === 'new' ||
      this.selectedOrder[0].status.toLowerCase() === 'pending' ||
      this.selectedOrder[0].status.toLowerCase() === 'processed' ||
      this.selectedOrder[0].status.toLowerCase() === 'revised' ||
      this.selectedOrder[0].status.toLowerCase() === 'namc_revised' ||
      this.selectedOrder[0].status.toLowerCase() === 'rfc' ||
      this.selectedOrder[0].status.toLowerCase() === 'eci_data'
    ) {
      this.orderId = this.selectedOrder[0].orderNumber;
      this.openNamcComments(this.selectedOrder);
    } else {
      this.errorMessage =
        'Namc Comment can only be Updated for Order Status New, Pending, Processed, Revised, Namc_Revised, Rfc and Eci_Data.';
      this.settingTimeOut();
    }
  }
  openNamcComments(selectedOrder: TrialOrderDetails[]) {
    const dialogRef = this.dialog.open(NamcCommentsActionComponent, {
      disableClose: true,
      width: '570px',
      height: '309px',
      data: {
        selectedOrder,
      },
    });

    dialogRef.afterClosed()?.subscribe((result: string | undefined) => {
      if (result === undefined) {
        this.selectedOrder = [];
        return;
      }
      if (result === 'Success') {
        this.namcCommentEmit.emit({
          success: true,
          message: `NAMC Comment for '${this.orderId}' Updated successfully.`,
        });
      } else {
        this.namcCommentEmit.emit({
          success: false,
          message: `NAMC Comment for '${this.orderId}' failed to update.`,
        });
      }
      this.selectedOrder = [];
    });
  }

  enableSap() {
    if (this.selectedOrder.length === 0) {
      this.customizederrorMessage = ['You need to select at least one order.'];
      this.settingTimeOut();
      return;
    }
    const dialogRef = this.dialog.open(SapInterfaceComponent, {
      width: '400px',
      data: {
        headerTitle: 'EnableSAP',
        selectedOrder: this.selectedOrder,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.namcCommentEmit.emit({ message: '', success: true });
      this.customizederrorMessage =
        Object.keys(result).length > 1 ? result.error : Object.values(result);
      this.customizedSuccessMessage = result.success ?? [];
      this.settingTimeOut();
    });
  }

  disableSap() {
    if (this.selectedOrder.length === 0) {
      this.customizederrorMessage = ['You need to select at least one order.'];
      this.settingTimeOut();
      return;
    }
    const dialogRef = this.dialog.open(SapInterfaceComponent, {
      width: '400px',
      data: {
        headerTitle: 'DisableSAP',
        selectedOrder: this.selectedOrder,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.namcCommentEmit.emit({ message: '', success: true });
      this.customizederrorMessage =
        Object.keys(result).length > 1 ? result.error : Object.values(result);
      this.customizedSuccessMessage = result.success ?? [];
      this.settingTimeOut();
    });
  }

  sendToSap() {
    const dialogRef = this.dialog.open(SapInterfaceComponent, {
      width: '400px',
      data: {
        headerTitle: 'SendToSAP',
        eventId: sessionStorage.getItem('eventId'),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.namcCommentEmit.emit({ message: '', success: true });
      this.errorMessageHeader = result.error?.message;
      if (
        Object.keys(result).length > 1 &&
        Object.keys(result.error).length > 1
      ) {
        this.customizederrorMessage = result.error.error;
      } else if (Object.keys(result).length === 1) {
        this.customizederrorMessage = Object.values(result);
      } else {
        this.customizederrorMessage = [];
      }
      this.customizedSuccessMessage = result.success ?? [];
      this.settingTimeOut();
    });
  }

  close() {
    this.customizederrorMessage.length = 0;
    this.customizedSuccessMessage.length = 0;
    this.errorMessageHeader = undefined;
  }

  cancelTrails() {
    const orderNumbers = this.selectedRows
      .map((row) => row.orderNumber)
      .join(',');

    const dialogRef = this.dialog.open(CancelTrailsComponent, {
      width: '400px',
      data: { orderNumbers, eventId: this.eventId },
    });
    dialogRef.afterClosed().subscribe((_result) => {});
  }

  exportOrders() {
    const roleDetails = JSON.parse(
      sessionStorage.getItem('roleDetails') ?? '{}'
    ) as SelectedRole;
    this.ngxUiLoaderService.start('getTrialOrdersData');
    const control: number[] = [];
    for (let i = 1; i <= Math.ceil(this.rowCount / 4200); i++) {
      control.push(1);
      this.trialOrderService
        .getGridData(this.eventId, this.start, this.limit, roleDetails)
        ?.subscribe({
          next: (result) => {
            this.trialOrderDetails = [
              ...this.trialOrderDetails,
              ...result.trialOrderData,
            ];
            control.pop();
            if (control.length === 0) {
              this.ngxUiLoaderService.stop('getTrialOrdersData');
              this.exportService.setExportOrderDetails(
                this.trialOrderDetails,
                this.currentRole,
                this.eventDetailsFlags.displayPOTrackingNumber,
                this.eventId
              );
            }
          },
          error: (err) => {
            this.ngxUiLoaderService.stop('getTrialOrdersData');
            const error = err as HttpErrorResponse;
            this.errorMessage = error.error.error;
            this.settingTimeOut();
          },
        });
      this.start = this.limit + 1;
      this.limit = this.limit + 4200;
    }
  }

  settingTimeOut() {
    setTimeout(() => {
      this.customizederrorMessage.length = 0;
      this.customizedSuccessMessage.length = 0;
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 7000);
  }

  viewTrails() {
    if (this.selectedRows.length === 1) {
      const data = JSON.stringify(this.selectedRows[0]);
      this.router.navigate(
        ['event-order/trial-orders/trialOrderList/viewTrails'],
        {
          state: { mode: 'view', data: data },
        }
      );
    } else if (this.selectedRows.length === 0 || !this.selectedRows) {
      const err = 'YOU NEED TO SELECT EXACTLY ONE ORDER';
      const currentPath = this.router.routerState.snapshot.url;

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentPath], {
          state: {
            err,
            myData: sessionStorage.getItem('eventId'),
            dummy: true,
          },
        });
      });
    } else {
      const err = 'YOU NEED TO SELECT ONE ORDER FOR THE SELECTED OPERATION';
      const currentPath = this.router.routerState.snapshot.url;

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentPath], {
          state: {
            err,
            myData: sessionStorage.getItem('eventId'),
            dummy: true,
          },
        });
      });
    }
  }

  openShipChangeDialog() {
    if (this.selectedOrder.length === 0 || !this.selectedOrder) {
      const err = 'YOU NEED TO SELECT ATLEAST ONE ORDER';

      this.namcCommentEmit.emit({
        success: false,
        message: err,
      });
      return;
    }
    const dialogRef = this.dialog.open(SupplierShipDateComponent, {
      width: '400px',
      data: 'FromNamc',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status) {
        const payloadObj = this.getConstructedPayloadShippingDate(
          result.result
        );
        this.ngxUiLoaderService.start('Loader-01');
        this.trialOrderService.sendSupplierSendRequest(payloadObj).subscribe({
          next: (res: { result: string; errorList: string }) => {
            this.ngxUiLoaderService.stop('Loader-01');
            if (res.result === 'Success') {
              this.namcCommentEmit.emit({
                success: true,
                message: 'Selected Orders Have Been Successfully Updated',
              });
            }
            this.selectedOrder.length = 0;
          },
          error: (err) => {
            this.ngxUiLoaderService.stop('Loader-01');
            this.namcCommentEmit.emit({
              success: false,
              message:
                err.status === 422
                  ? err.error.errorList.toString()
                  : 'Something Went Wrong, Please Contact Sytem Administrator',
            });
          },
        });
      }
    });
  }

  openShipRequestDialog() {
    if (this.selectedOrder.length === 0 || !this.selectedOrder) {
      const err = 'YOU NEED TO SELECT ATLEAST ONE ORDER';

      this.namcCommentEmit.emit({
        success: false,
        message: err,
      });
      return;
    }
    if (this.selectedOrder.length > 1) {
      const err = `PART NO DATA LENGTH IS NOT AS EXPECTED FOR THE ORDER NO: ${this.selectedOrder[0].orderNumber}`;
      this.namcCommentEmit.emit({
        success: false,
        message: err,
      });
      return;
    }
    const isValid = this.selectedOrder.every((item) => item.statusId !== 14);
    if (isValid) {
      const err = 'MAKE SURE ALL THE SELECTED ORDES SHOULD BE IN RFC STATE';

      this.namcCommentEmit.emit({
        success: false,
        message: err,
      });
      return;
    }
    const dialogRef = this.dialog.open(ShipChangeRequestComponent, {
      width: '400px',
      data: {
        requestForSuppShipDtChng:
          this.selectedOrder[0].requestForSuppShipDtChng,
        requestForSuppShipcmmntChng:
          this.selectedOrder[0].requestForSuppShipcmmntChng,
        deliveryDate: this.selectedOrder[0].orderDueDate,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status) {
        const payloadObj = this.getContructedPayload(result.data);
        this.ngxUiLoaderService.start('Loader-01');
        this.trialOrderService.sendSupplierSendRequest(payloadObj).subscribe({
          next: (res: { result: string; errorList: string }) => {
            this.ngxUiLoaderService.stop('Loader-01');
            if (res.result === 'Success') {
              this.namcCommentEmit.emit({
                success: true,
                message: 'Selected Orders Have Been Successfully Updated',
              });
            }
            this.selectedOrder.length = 0;
          },
          error: (err) => {
            this.ngxUiLoaderService.stop('Loader-01');
            this.namcCommentEmit.emit({
              success: false,
              message:
                err.status === 422
                  ? err.error.errorList.toString()
                  : 'Something Went Wrong, Please Contact Sytem Administrator',
            });
          },
        });
      }
    });
  }

  openFilterDialog() {
    this.dialog.open(FiltersComponent, {
      width: '800px',
      data: {
        isSupplier: false,
        OriginalColumns: [
          {
            value: 'ORDER STATUS',
            label: 'ORDER STATUS',
          },
          {
            value: 'ORDER TRACKING NO',
            label: 'ORDER TRACKING NO',
          },
          {
            value: 'ORDER TYPE',
            label: 'ORDER TYPE',
          },
          {
            value: 'PO Tracking No',
            label: 'PO Tracking No',
          },
          {
            value: 'SAP Status',
            label: 'SAP Status',
          },
          {
            value: 'EVENT NAME',
            label: 'EVENT NAME',
          },
          {
            value: 'PROJECT',
            label: 'PROJECT',
          },
          {
            value: 'PSMS ROUTING',
            label: 'PSMS ROUTING',
          },
          {
            value: 'SPECIALIST NAME',
            label: 'SPECIALIST NAME',
          },
          {
            value: 'SUPPLIER CODE',
            label: 'SUPPLIER CODE',
          },
          {
            value: 'KANBAN NUMBER',
            label: 'KANBAN NUMBER',
          },
          {
            value: 'PART NUMBER',
            label: 'PART NUMBER',
          },
          {
            value: 'PART NAME',
            label: 'PART NAME',
          },
          {
            value: 'REVISION',
            label: 'REVISION',
          },
          {
            value: 'NAMC DUE DATE',
            label: 'NAMC DUE DATE',
          },
          {
            value: 'SUPPLIER PICKUP DATE',
            label: 'SUPPLIER PICKUP DATE',
          },
          {
            value: 'SUPPLIER SHIP DATE',
            label: 'SUPPLIER SHIP DATE',
          },
          {
            value: 'TOTAL QTY',
            label: 'TOTAL QTY',
          },
          {
            value: 'SUPPLIER NAME',
            label: 'SUPPLIER NAME',
          },
          {
            value: 'NAMC COMMENT',
            label: 'NAMC COMMENT',
          },
          {
            value: 'SUPPLIER COMMENT',
            label: 'SUPPLIER COMMENT',
          },
          {
            value: 'STORE ADDRESS',
            label: 'STORE ADDRESS',
          },
          {
            value: 'LINE SIDE ADDRESS',
            label: 'LINE SIDE ADDRESS',
          },
          {
            value: 'DOCK CODE',
            label: 'DOCK CODE',
          },
          {
            value: 'PALLET CODE',
            label: 'PALLET CODE',
          },
          {
            value: 'MANIFEST NUMBER',
            label: 'MANIFEST NUMBER',
          },
          {
            value: 'RECEIVER NUMBER',
            label: 'RECEIVER NUMBER',
          },
          {
            value: 'NA PIPE STATUS',
            label: 'NA PIPE STATUS',
          },
          {
            value: 'ECI NUMBER',
            label: 'ECI NUMBER',
          },
          {
            value: 'ECI REVISION',
            label: 'ECI REVISION',
          },
          {
            value: 'SPECIALIST CODE',
            label: 'SPECIALIST CODE',
          },
          {
            value: 'SHIPPING DATE',
            label: 'SHIPPING DATE',
          },
          {
            value: 'TRAILER NUMBER',
            label: 'TRAILER NUMBER',
          },
          {
            value: 'SHIPPING CARRIER',
            label: 'SHIPPING CARRIER',
          },
          {
            value: 'TRACKING/AIRLBILL NO',
            label: 'TRACKING/AIRLBILL NO',
          },
          {
            value: 'LIFE CYCLE CODE',
            label: 'LIFE CYCLE CODE',
          },
          {
            value: 'WORK ORDER NUMBER',
            label: 'WORK ORDER NUMBER',
          },
          {
            value: 'SUPPLIER SHIP DATE CHANGE REQUEST',
            label: 'SUPPLIER SHIP DATE CHANGE REQUEST',
          },
          {
            value: 'SUPPLIER SHIP DATE CHANGE COMMENT',
            label: 'SUPPLIER SHIP DATE CHANGE COMMENT',
          },
          {
            value: 'OWK LABEL LINE1',
            label: 'OWK LABEL LINE1',
          },
          {
            value: 'OWK LABEL LINE2',
            label: 'OWK LABEL LINE2',
          },
          {
            value: 'SCAN COMMENT',
            label: 'SCAN COMMENT',
          },
          {
            value: 'SEND TO SAP FLAG',
            label: 'SEND TO SAP FLAG',
          },
          {
            value: 'PO LINE NUMBER',
            label: 'PO LINE NUMBER',
          },
          {
            value: 'ARIBA PR NO',
            label: 'ARIBA PR NO',
          },
          {
            value: 'ARIBA ORDER STATUS',
            label: 'ARIBA ORDER STATUS',
          },
          {
            value: 'ARIBA ORDER COMMENT',
            label: 'ARIBA ORDER COMMENT',
          },
          {
            value: 'SAP SUBMISSION COMMENT',
            label: 'SAP SUBMISSION COMMENT',
          },
        ],
      },
    });
  }

  openSupplierNotification() {
    if (this.selectedRows.length === 0 || !this.selectedRows) {
      const err = 'YOU NEED TO SELECT ATLEAST ONE ORDER';

      this.namcCommentEmit.emit({
        success: false,
        message: err,
      });
      return;
    }
    const dialogRef = this.dialog.open(SupplierMailComponent, {
      width: '50%',
      data: this.selectedRows.map((selectedRow) => selectedRow.orderNumber),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status) {
        this.namcCommentEmit.emit({
          success: true,
          message: result.outcome,
        });
      }
    });
  }

  getContructedPayload(result: {
    namcComment: string;
    deliveryDate: string;
    promiseDate: string;
  }) {
    const ordersCount = this.selectedOrder.length;
    return {
      trialEventId: this.eventId,
      appendOrderNumber: this.selectedOrder.map((e) => e.orderNumber).join('#'),
      emailIdsList: '',
      message: '',
      emailSubject: '',
      emailFrom: '',
      appendComment: result?.namcComment
        ? Array.from({ length: ordersCount }, () => result.namcComment).join(
            '#'
          )
        : this.selectedOrder.map((e) => e.namcComment).join('#'),
      appendPOTrackingNumber: this.returnDefVal(ordersCount),
      psmsToRouting: this.returnDefVal(ordersCount),
      appendDeliveryDate: this.returnDefVal(ordersCount, result.deliveryDate),
      appendTotalQty: this.returnDefVal(ordersCount),
      appendStore: this.returnDefVal(ordersCount),
      appendLineSide: this.returnDefVal(ordersCount),
      appendEciNumber: this.returnDefVal(ordersCount),
      appendEciRevision: this.returnDefVal(ordersCount),
      appendTrailerNumber: this.returnDefVal(ordersCount),
      appendShippingCarrier: this.returnDefVal(ordersCount),
      appendTrackingAirbillNumber: this.returnDefVal(ordersCount),
      appendShippingDateTime: this.returnDefVal(ordersCount),
      appendKanbanNo: this.returnDefVal(ordersCount),
      appendPartName: this.returnDefVal(ordersCount),
      appendWorkOrderNumber: '',
      appendLifeCycleCode: this.returnDefVal(ordersCount),
      appendPromiseDate:
        result.promiseDate === 'Reject'
          ? this.returnDefVal(ordersCount, 'RejectReject')
          : this.returnDefVal(ordersCount, `${result.promiseDate}supp`),
      appendOWKLabelLine1: this.returnDefVal(ordersCount),
      appendOWKLabelLine2: this.returnDefVal(ordersCount),
      appendPartNumber: this.returnDefVal(ordersCount),
      appendSupplierName: this.returnDefVal(ordersCount),
      appendSupplierCd: this.returnDefVal(ordersCount),
      appendSupplierPickupDate: this.returnDefVal(ordersCount),
      supplierComment: this.returnDefVal(ordersCount),
      specialistName: this.returnDefVal(ordersCount),
      specialistCode: this.returnDefVal(ordersCount),
      poLineNo: '',
      appendSuppShpDtChng: '',
      appendSuppShpDtCmmnt: '',
      appendPalletCode: this.returnDefVal(ordersCount),
      appendNaPipeStatus: '',
    };
  }

  getConstructedPayloadShippingDate(result: {
    namcProvidedShipDate: string;
    namcComments: string;
  }) {
    const ordersCount = this.selectedOrder.length;
    return {
      trialEventId: this.eventId,
      appendOrderNumber: this.selectedOrder.map((e) => e.orderNumber).join('#'),
      emailIdsList: '',
      message: '',
      emailSubject: '',
      emailFrom: '',
      appendComment: result?.namcComments
        ? this.returnDefVal(ordersCount, result?.namcComments)
        : this.selectedOrder.map((e) => e.namcComment).join('#'),
      appendPOTrackingNumber: this.returnDefVal(ordersCount),
      psmsToRouting: this.returnDefVal(ordersCount),
      appendDeliveryDate: this.returnDefVal(ordersCount),
      appendTotalQty: this.returnDefVal(ordersCount),
      appendStore: this.returnDefVal(ordersCount),
      appendLineSide: this.returnDefVal(ordersCount),
      appendEciNumber: this.returnDefVal(ordersCount),
      appendEciRevision: this.returnDefVal(ordersCount),
      appendTrailerNumber: this.returnDefVal(ordersCount),
      appendShippingCarrier: this.returnDefVal(ordersCount),
      appendTrackingAirbillNumber: this.returnDefVal(ordersCount),
      appendShippingDateTime: this.returnDefVal(ordersCount),
      appendKanbanNo: this.returnDefVal(ordersCount),
      appendPartName: this.returnDefVal(ordersCount),
      appendWorkOrderNumber: '',
      appendLifeCycleCode: this.returnDefVal(ordersCount),
      appendPromiseDate: result?.namcProvidedShipDate
        ? this.returnDefVal(ordersCount, result.namcProvidedShipDate)
        : this.selectedOrder.map((e) => e.promiseDate).join('#'),
      appendOWKLabelLine1: this.returnDefVal(ordersCount),
      appendOWKLabelLine2: this.returnDefVal(ordersCount),
      appendPartNumber: this.returnDefVal(ordersCount),
      appendSupplierName: this.returnDefVal(ordersCount),
      appendSupplierCd: this.returnDefVal(ordersCount),
      appendSupplierPickupDate: this.returnDefVal(ordersCount),
      supplierComment: this.returnDefVal(ordersCount),
      specialistName: this.returnDefVal(ordersCount),
      specialistCode: this.returnDefVal(ordersCount),
      poLineNo: '',
      appendSuppShpDtChng: '',
      appendSuppShpDtCmmnt: '',
      appendPalletCode: this.returnDefVal(ordersCount),
      appendNaPipeStatus: '',
    };
  }

  returnDefVal(ordersCount: number, putVal = 'defVal') {
    return Array.from({ length: ordersCount }, () => putVal).join('#');
  }

  saveColumns(updateStorage: boolean) {
    const subscription = this.trialOrderService.namcValue$.subscribe(
      (value: { label: string; value: string }[]) => {
        if (updateStorage) {
          localStorage.setItem('namc-columns-saved', JSON.stringify(value));
          updateStorage = false;
          subscription?.unsubscribe();
        }
      }
    );
    this.successMessage = 'Preferences Have Been Successfully saved';
    setTimeout(() => {
      this.successMessage = undefined;
    }, 3000);
  }
}
