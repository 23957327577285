<div class="popup-container">
  <button
    class="close black-button"
    (click)="onNoClick()"
    (keydown)="onNoClick()"
  >
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="p-3 mb-0 roboto-medium">
    <fa-icon class="mt-1 me-2" [icon]="faWarning"></fa-icon>
    Delete Training Material
  </h2>
  <hr id="shadow" />
  <br />
  <app-notification-message
    [errorMessage]="errorMessage"
    [successMessage]="successMessage"
  >
  </app-notification-message>
  <div>
    <p class="roboto-bold paragraph">
      Are you sure you want to delete this Training Material?
    </p>
  </div>
  <div class="right-align mt-3">
    <button (click)="saveClick()" class="b2-button add roboto-medium">
      DELETE
    </button>
    <button (click)="onNoClick()" class="b2 me-3 ms-3 roboto-bold">
      CANCEL
    </button>
  </div>
</div>
