import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import {
  Category,
  DeleteMap,
  Document,
  ReorderDocument,
  ReorderSubmit,
  SaveDocumentInterface,
} from 'src/app/core/model/manage-online-namc';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ManageOnlineTrainingService {
  notificationMessage!: string | undefined;
  previousURL = '';
  currentURL = '';
  baseApiUrl: string;

  private readonly dataSubject = new BehaviorSubject<any>(null);

  data$ = this.dataSubject.asObservable();

  updateData(data: string) {
    this.dataSubject.next(data);
  }

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  categoryDropDown(category: string) {
    return this.http
      .get<Category[]>(
        `${this.baseApiUrl}/tmna-namc/training-material/category?categoryType=${category}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  onLoadTraining(category: string) {
    return this.http
      .get<Document>(
        `${this.baseApiUrl}/training-material?categoryType=${category}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  reorderOnload(category: string) {
    return this.http
      .get<ReorderDocument>(
        `${this.baseApiUrl}/tmna-namc/training-material/reorder?categoryType=${category}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  addTrainingMaterial(payload: any) {
    return this.http
      .post<SaveDocumentInterface>(
        `${this.baseApiUrl}/tmna-namc/training-material`,
        payload,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  editTrainingMaterial(payload: any) {
    return this.http
      .patch<SaveDocumentInterface>(
        `${this.baseApiUrl}/tmna-namc/training-material`,
        payload,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  downloadDocument(documentId: string) {
    return this.http
      .get<{ message?: string; result: boolean }>(
        `${this.baseApiUrl}/tmna-namc/training-material?documentId=${documentId}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  saveReorder(payload: any) {
    return this.http
      .patch<ReorderSubmit>(
        `${this.baseApiUrl}/tmna-namc/training-material/reorder`,
        payload,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  deleteDocument(documentId: string, fileName: string) {
    return this.http
      .delete<DeleteMap>(
        `${this.baseApiUrl}/tmna-namc/training-material?documentId=${documentId}&fileName=${fileName}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }
}
