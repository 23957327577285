import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  fromEvent,
  interval,
  merge,
  timer,
} from 'rxjs';
import {
  debounceTime,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService {
  private readonly TIMEOUT_DURATION = 60 * 60 * 1000;
  private idleSubscription!: Subscription;
  private readonly LoggedOutSubject: BehaviorSubject<'loggedOut' | null>;
  public LoggedOutStatus: Observable<'loggedOut' | null>;
  baseApiUrl: string;

  constructor(
    private readonly userService: UserService,
    private readonly loginService: LoginService
  ) {
    this.LoggedOutSubject = new BehaviorSubject<'loggedOut' | null>(null);
    this.LoggedOutStatus = this.LoggedOutSubject.asObservable();
    this.baseApiUrl = environment.baseAPIURL;
  }

  startTracking(): void {
    const userActivity$: Observable<Event> = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'touchstart')
    );

    this.idleSubscription = userActivity$
      .pipe(
        debounceTime(300),
        switchMap(() =>
          timer(this.TIMEOUT_DURATION).pipe(
            tap(() => this.showSessionTimeoutNotification()), 
            startWith(-1),
            switchMap(() =>
              interval(10 * 60 * 1000).pipe(
                take(6),
                tap((count) =>
                  console.log(`Inactive for ${(count + 1) * 10} minutes`)
                )
              )
            )
          )
        )
      )
      .subscribe();
  }

  stopTracking(): void {
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }

  showSessionTimeoutNotification(): void {
    const userChoice = confirm(
      'The session has been timed out. Do you want to log out?'
    );
    localStorage.clear();
    sessionStorage.clear();
    this.userService.clearUserDetails();
    this.userService.clearRoleSelected();
    this.LoggedOutSubject.next('loggedOut');
    const cookieName = this.baseApiUrl;
    document.cookie = `${cookieName}=; path=/; domain=localhost; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    this.loginService.refreshToken();
    if (userChoice) {
      this.loginService.login();
    }
  }
}
