<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div class="d-flex">
  <div class="col-12 col-md-6">
    <div class="d-flex mt-4">
      <div class="d-flex mx-4" style="flex-direction: column">
        <span class="required-color bold-label header-display px-2 mb-2"
          >ATTRIBUTE ID</span
        >
        <input
          type="text"
          [disabled]="true"
          for="attributeId"
          size="100"
          [(ngModel)]="attId"
        />
      </div>
    </div>
    <div class="d-flex mt-4">
      <div class="d-flex mx-4" style="flex-direction: column">
        <span class="required-color bold-label header-display px-2 mb-2"
          >ATTRIBUTE TYPE</span
        >
        <input
          type="text"
          [disabled]="true"
          for="attributeType"
          size="100"
          [(ngModel)]="attType"
        />
      </div>
    </div>
    <form [formGroup]="modifyAttributeForm">
      <div class="d-flex mt-4">
        <div class="d-flex mx-4" style="flex-direction: column">
          <span class="required-color header-display px-2 mb-2"
            >ATTRIBUTE VALUE</span
          >
          <input
            type="text"
            for="attributeValue"
            size="100"
            formControlName="attValue"
            maxlength="100"
            disableSpecialCharacters
          />
        </div>
      </div>
      <div
        class="d-flex mx-4"
        *ngIf="
          this.modifyAttributeForm.controls['attValue'].invalid &&
          (this.modifyAttributeForm.controls['attValue'].dirty ||
            this.modifyAttributeForm.controls['attValue'].touched)
        "
      >
        <small
          class="error col-4"
          *ngIf="
            this.modifyAttributeForm.controls['attValue'].hasError('required')
          "
        >
          Please provide Attribute Value
        </small>
      </div>
      <div class="d-flex mt-4">
        <div class="d-flex mx-4" style="flex-direction: column">
          <span class="required-color bold-label header-display px-2 mb-2"
            >ATTRIBUTE DESCRIPTION</span
          >
          <input
            type="text"
            for="attributeDescription"
            size="100"
            formControlName="attDesc"
            maxlength="70"
            disableSpecialCharacters
          />
        </div>
      </div>
      <div
        class="d-flex mx-4"
        *ngIf="
          this.modifyAttributeForm.controls['attDesc'].invalid &&
          (this.modifyAttributeForm.controls['attDesc'].dirty ||
            this.modifyAttributeForm.controls['attDesc'].touched)
        "
      >
        <small
          class="error col-4"
          *ngIf="
            this.modifyAttributeForm.controls['attDesc'].hasError('required')
          "
        >
          Please provide Attribute Description
        </small>
      </div>
    </form>
  </div>
  <div class="mt-4 col-12 col-md-6">
    <div class="d-flex mx-4" style="flex-direction: column; min-height: 250px">
      <table class="table tproms-shadow" aria-describedby="reason">
        <thead>
          <tr class="titleColumn">
            <th scope="col">NOTE</th>
          </tr>
        </thead>
        <tbody>
          <tr class="contentColumn">
            <td>
              1. Before changing an Attribute Value, please check the max length
              of the field from respective database table.
            </td>
          </tr>
          <tr class="contentColumn">
            <td>
              2. While deleting an Attribute, please make sure you are not
              deleting the last attribute of a group.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<footer class="footer">
  <button class="cancel-button ms-2" (click)="cancel()">CANCEL</button>

  <div class="button-container">
    <button
      class="b2-button add"
      (click)="updateAttribute()"
      [disabled]="
        !this.modifyAttributeForm.controls['attValue'].value ||
        !this.modifyAttributeForm.controls['attDesc'].value
      "
    >
      UPDATE ATTRIBUTE
    </button>
  </div>
</footer>
