<div class="manageUser-btn">
  <button
    class="me-3 b2-button add"
    (click)="routeAddUser()"
    style="float: right"
  >
    ADD USER
  </button>
</div>
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<app-grid
  [columnDefs]="columnDefs"
  [displayRowData]="displayRowData"
></app-grid>
