import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateTrailsService } from 'src/app/services/event-and-orders/create-trails.service';
import { UserService } from 'src/app/services/user.service';
import { UserResponse } from '../../model/user.model';
@Component({
  selector: 'app-cancel-trails',
  templateUrl: './cancel-trails.component.html',
  styleUrls: ['./cancel-trails.component.scss'],
})
export class CancelTrailsComponent {
  response: any;
  userId!: string;
  Comments = '';
  constructor(
    private readonly dialogRef: MatDialogRef<CancelTrailsComponent>,
    private readonly CreateTrailsService: CreateTrailsService,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: { orderNumbers: string[]; eventId: number },
    private readonly userService: UserService
  ) {}
  ngOnInit(): void {
 
    this.getUserDetails();
  }

  onYesClick(): void {
    const payload = {
      appendcomments: this.Comments,
      ordernumbers: this.data.orderNumbers,
      updatedby: this.userId,
    };
    this.CreateTrailsService.cancelTrails(payload).subscribe({
      next: (res) => {
        this.response = JSON.parse(res);
        this.router
          .navigateByUrl('/dummy', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['event-order/trial-orders/trialOrderList'], {
              state: {
                res: this.response,
                myData: this.data.eventId,
                cancel: true,
              },
            });
          });
      },
      error: (_err) => {},
    });

    this.dialogRef.close();
  }



 getUserDetails() {
  this.userService.getUserDetails()?.subscribe((response: UserResponse) => {
    this.userId = response.userDetails.userId;
  
  });
}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
