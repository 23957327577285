
<div (ngSubmit)="onSubmit()">

  <div class="form-container">
    <div class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode"  class="vertical-bar"></div>
        <label for="orderTracking">ORDER TRACKING NUMBER</label>
      </div>
      <input id="orderTracking" type="text" [readonly]="isViewMode" [(ngModel)]="ordernumber"
        [ngClass]="{ 'readonly-input': isViewMode }" class="form-control" disableSpecialCharacters />

    </div>
    <div class="form-group">
      <div class="label-container">
      <label for="psmsRouting">PSMS ROUTING</label>
      </div>
      <input id="psmsRouting" maxlength="5" [(ngModel)]="psmsRouting" 
        type="text" class="form-control" disableSpecialCharacters/>
    </div>
    <div class="form-group">
      <div class="label-container">
      <label for="poTracking">PO TRACKING NUMBER</label>
      </div>
      <input id="poTracking" type="text" [readonly]="isViewMode" [(ngModel)]="trackingnumber" 
      [ngClass]="{ 'readonly-input': isViewMode }"  maxlength="12" class="form-control" disableSpecialCharacters/>
    </div>

    <div class="form-group">
      <div class="label-container">
        <div class="vertical-bar"></div>
        <label for="supplierCode">SUPPLIER CODE</label>
      </div>
      <p-dropdown  *ngIf="supplierCodes?.length"
      optionLabel="supplierCode"
      optionValue="supplierCode"
      [(ngModel)]="suppliercode"
      (ngModelChange)="onSupplierCodeChange($event)"
      [options]="supplierCodes"
      scrollHeight="200px"
      [filter]="true"
      [virtualScroll]="true"
      [virtualScrollItemSize]="10"
      >
      </p-dropdown>
      <div class="error-message" *ngIf="mandatoryFieldErrors?.supplierCode">
        {{ mandatoryFieldErrors.supplierCode }}
      </div>
    </div>
    
    <div class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="supplierName">SUPPLIER NAME</label>
      </div>
      <input
        type="text"
        [readonly]="isViewMode "
        maxlength="12"
        class="form-control"
        [(ngModel)]="suppliername"
        [ngClass]="{ 'readonly-input': isViewMode }"
        disableSpecialCharacters
      />
    </div>
    
    <div class="form-group">
      <label for="kanbanNumber">KANBAN NUMBER</label>
      <input id="kanbanNumber" maxlength="4" type="text" [(ngModel)]="kanbanNumber" class="form-control" disableSpecialCharacters />
    </div>
    <div class="form-group">
      <div class="label-container">
        <div  class="vertical-bar"></div>
        <label for="partNumber">PART NUMBER</label>
      </div>
      <input id="partNumber" maxlength="12" type="text" [(ngModel)]="partNumber"  class="form-control" disableSpecialCharacters />
        <div class="error-message" *ngIf="mandatoryFieldErrors?.partNumber">
          {{ mandatoryFieldErrors.partNumber }}
        </div>
        <div class="error-message" *ngIf="lengthError.partNumbererror&& !mandatoryFieldErrors?.partNumber">
          {{ lengthError.partNumbererror }}
        </div>
    </div>
    <div class="form-group">
      <div class="label-container">
        <div  class="vertical-bar"></div>
        <label for="partName">PART NAME</label>
      </div>
      <input id="partName" type="text" class="form-control" [(ngModel)]="partName" maxlength="50" disableSpecialCharacters />
        <div class="error-message" *ngIf="mandatoryFieldErrors?.partName">
          {{ mandatoryFieldErrors.partName }}
        </div>
    </div>
    <div   class="form-group">
      <div class="label-container">
 
        <label for="partName">COMMENT</label>
      </div>
      <input id="partName" type="text" class="form-control" [(ngModel)]="comment" maxlength="50" disableSpecialCharacters />
    </div>
  </div>
  <div class="form-container">

    <div class="form-group">
      <label for="plant1">PLANT 1 QUANTITY</label>
      <input type="text" id="plant1" maxlength="6" [(ngModel)]="plant1Qty"
        class="form-control" disableSpecialCharacters/>
    </div>
    <div class="form-group">
      <label for="plant2">PLANT 2 QUANTITY</label>
      <input id="plant2" maxlength="6" type="text" [(ngModel)]="plant2Qty" 
        class="form-control" disableSpecialCharacters/>
    </div>
    <div class="form-group">
      <label for="plant3">PLANT 3 QUANTITY</label>
      <input id="plant3" maxlength="6"  [(ngModel)]="plant3Qty" type="text"
        class="form-control" disableSpecialCharacters/>
    </div>
    <div class="form-group">
      <div class="label-container">
        <div  class="vertical-bar"></div>
        <label for="totalQuantity">TOTAL QUANTITY</label>
      </div>
      <input id="totalQuantity" type="text" maxlength="6" [(ngModel)]="totalQuantity"  class="form-control" disableSpecialCharacters />
        <div class="error-message" *ngIf="mandatoryFieldErrors?.totalQuantity">
          {{ mandatoryFieldErrors.totalQuantity }}
        </div>
        <div class="error-message" *ngIf="totalErrorMessage && !mandatoryFieldErrors?.totalQuantity">
          {{ totalErrorMessage }}
        </div>
    </div>

    <div class="form-group">
      <label for="storeAddress"> STORE ADDRESS</label>
      <input id="storeAddress" maxlength="10" type="text" [(ngModel)]="storeAddress"  class="form-control" disableSpecialCharacters/>
    </div> 
    <div class="form-group">
      <label for="lineSideAdress">LINE SIDE ADDRESSS</label>
      <input id="lineSideAdress" maxlength="10" type="text" [(ngModel)]="lineSideAdress" disableSpecialCharacters
       class="form-control" />
    </div>
  </div>


  <div class="form-container">
    <div class="form-group">
      <label for="lifecycleCode">LIFE CYCLE CODE</label>
      <input id="lifecycleCode" maxlength="1" [(ngModel)]="lifeCycleCode"
        type="text" class="form-control" disableSpecialCharacters/>
    </div>

    <div class="form-group">
      <label for="manifestNumber">MANIFEST NUMBER</label>
      <input id="manifestNumber" type="text" [(ngModel)]="manifestNumber"
        maxlength="8" class="form-control" disableSpecialCharacters/>
    </div>
    <div class="form-group">
      <div class="label-container">
        <div  class="vertical-bar"></div>
        <label for="supplierDate">NAMC DUE DATE </label>
      </div>
      <input id="supplierDate" type="date" class="form-control datepicker" [(ngModel)]="namcDate"
      />

        <div class="error-message" *ngIf="dateErrors.namcDate ||validdaterror.nameduedate">
          {{ dateErrors.namcDate }}{{validdaterror.nameduedate}}
        </div>
    </div>

   
    <div class="form-group">
      <div class="label-container">
        <div  class="vertical-bar"></div>
        <label for="supplierDate">SUPPLIER PICKUP DATE </label>
      </div>
      <input id="supplierDate" type="date" class="form-control datepicker" [(ngModel)]="supplierPickupDate"
      />
    
      <div class="error-message" *ngIf="dateErrors.supplierPickupDate||validdaterror.pickupdate">
        {{ dateErrors.supplierPickupDate }} {{validdaterror.pickupdate}}
      </div>
    </div>
    <div class="form-group">
      <label for="sendtosap">SEND TO SAP FLAG (Y/N)</label>
      <div class="fakeClass">
        <input
          type="radio"
          [(ngModel)]="selectedOption"
          [value]="'YES'"
          (keydown)="toggleSelection('YES')"
        />
        <div class="mt-2">YES</div>
    
        <input
          type="radio"
          [(ngModel)]="selectedOption"
          [value]="'NO'"
          (keydown)="toggleSelection('NO')"
        />
        <div class="mt-2">NO</div>
      </div>
    </div>
    
    <div class="form-group">
      <label for="palletCode">PALLET CODE</label>
      <input id="palletCode"[(ngModel)]="palletCode"  type="text"
        maxlength="2" class="form-control" />
    </div>

  </div>
  <div class="form-container" style="margin-bottom: 20px;">
    <div class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="rows">ORDER QUANTITY NAMES</label>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th> Quantity Name</th>
              <th> Quantity </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of orderQuantities; let i = index">
              <td>
                <input type="text" class="no-border" [(ngModel)]="item.name" [readonly]="isViewMode" [ngClass]="{ 'readonly-input': isViewMode }" name="name{{i}}" disableSpecialCharacters/>
              </td>
              <td>
                <input type="number" [(ngModel)]="item.quantity" name="quantity{{i}}" />
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>
      </div>



    </div>

  </div>
</div>
<br>
<div class="create_footer fixed-bottom ">
  <button class="cancel me-3 mt-2 roboto-bold cancelbtnalign" (click)="cancel()">
    CANCEL
  </button>

  <button class="black-button blackbtnalign me-3 mt-2" (click)="onSubmit()" > SUBMIT </button>
</div>