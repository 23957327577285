<div
  *ngIf="successMessage !== undefined"
  class="alert alert-success alert-dismissible fade show ms-4 me-4 p-2"
  role="alert"

>
  <button
    type="button"
    class="btn-close"
    (click)="close()"
    data-bs-dismiss="alert"
    aria-label="close"
  ></button>
  <strong class="ps-2">{{ successMessage }}</strong>
</div>
<div
  *ngIf="errorMessage !== undefined"
  class="alert alert-danger alert-dismissible ms-4 me-4 p-2"
 
>
  <button
    type="button"
    class="btn-close"
    (click)="close()"
    data-bs-dismiss="alert"
    aria-label="close"
  ></button>
  <strong class="ps-2" [style.white-space]="'pre-line'">{{ errorMessage }}</strong>
</div>

