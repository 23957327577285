<div id="overlay" class="hidden"></div>
<div class="cont">
    <div class="navigation">
        <app-toolbar id="toolbarheight" class="toolbar-top"></app-toolbar>
        <app-breadcrumb [requiredDisplayCheck]="requiredDisplayCheck"></app-breadcrumb>
    </div>
    <ngx-ui-loader></ngx-ui-loader>
    <router-outlet class="body-cont"></router-outlet>
    <app-footer id="footerheight" class="footer-bottom"></app-footer>
</div>
