<h2 *ngIf="isSearchBtnDisable; else addCheck" class="mt-2 heading-margin">
  Modify User : {{ userId && userId.charAt(0).toUpperCase() + userId.slice(1) }}
</h2>
<ng-template #addCheck>
  <h2 class="mt-2 heading-margin">Add New User</h2>
</ng-template>
<div class="userid">
  <span class="required-color px-1" *ngIf="isSearchBtnDisable == false"></span>
  <label for="userid">USER ID</label><br />
  <input
    type="text"
    id="userid"
    [disabled]="isInputDisable"
    [(ngModel)]="userId"
    [class.errorbox]="isSearchBtnClicked && !userId"
    maxlength="30"
    disableSpecialCharacters
  />
  <button
    class="b2-button ms-3"
    *ngIf="isSearchBtnDisable == false"
    (click)="search('add')"
  >
    SEARCH
  </button>
  <p class="error" *ngIf="isSearchBtnClicked">This field is mandatory</p>
</div>

<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div style="border: 1px solid #bdbdbd; opacity: 0.5; margin: 0px"></div>
<div class="formbody pt-4">
  <div class="left-half">
    <div class="formalign">
      <label for="firstName">First Name</label><br />
      <input type="text" id="firstName" disabled="true" [ngModel]="firstName" />
    </div>
    <div class="formalign">
      <label for="lastName">Last Name</label><br />
      <input type="text" id="lastName" disabled="true" [ngModel]="lastName" />
    </div>
    <div class="formalign">
      <label for="title">Title</label><br />
      <input type="text" id="title" disabled="true" [ngModel]="title" />
    </div>
    <div class="formalign">
      <div style="margin-bottom: 10px" class="required-color label px-2">
        Status
      </div>
      <br />
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="example-radio-group d-flex gap-2"
        [(ngModel)]="userActiveFlag"
      >
        <mat-radio-button
          class="example-radio-button required-display"
          [value]="option.value"
          *ngFor="let option of options"
        >
          {{ option.label }}
        </mat-radio-button>
      </mat-radio-group>
      <p class="error mt-2" *ngIf="isStatusChanged">This field is mandatory</p>
    </div>
    <div class="formalign">
      <label style="margin-top: 10px" for="contactNumber">Contact Number</label
      ><br />
      <input
        type="text"
        id="contactNumber"
        disabled="true"
        [ngModel]="contactNumber"
      />
    </div>
    <div class="formalign" style="margin-bottom: 20px">
      <label for="emailAddress">Email Address</label>
      <input type="email" id="emailAddress" disabled="true" [ngModel]="email" />
    </div>
  </div>
  <div class="right-half">
    <h2>ROLE FOR NAMC</h2>
    <table aria-label="NamcRoles">
      <thead>
        <tr>
          <th style="width: 20%">NAMC</th>
          <th style="width: 42%">ROLE</th>
          <th style="width: 30%">SPECIALIST CODE</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let namc of namcRoleList.namcList; let i = index">
          <td>
            {{ namc.shortName }}
          </td>
          <td>
            <p-dropdown
              [options]="namcRoleList.roleList"
              optionLabel="roleDesc"
              optionValue="roleId"
              class="dropdownOptions"
              placeholder="(Select)"
              styleClass="w-50"
              (onChange)="roleChange($event, namc.code)"
              [(ngModel)]="selectedRole[i]"
            >
            </p-dropdown>
          </td>
          <td>
            <input
              style="width: -webkit-fill-available"
              type="number"
              [(ngModel)]="spclCodeValue[i]"
              (change)="onInputChange(namc.code)"
              (keydown)="onKeyDown($event)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<footer class="footer">
  <button class="cancel-button ms-2" (click)="cancel()">CANCEL</button>

  <div class="button-container">
    <button
      class="b2-button add"
      *ngIf="isSearchBtnDisable == false"
      [disabled]="!isAddBtnDisable"
      (click)="onSubmit()"
    >
      ADD USER
    </button>
    <button
      class="b2-button add"
      *ngIf="isSearchBtnDisable == true"
      (click)="onSubmit()"
    >
      EDIT USER
    </button>
  </div>
</footer>
