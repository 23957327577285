<div class="popup-container">
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="p-3 mb-0 roboto-medium">Supplier Ship Date</h2>
  <hr id="shadow" />
  <br />
  <div class="dialog-body">
    <p>
      Please enter the request Supplier Ship Date(MM/DD/YYYY) change for the
      selected order(s)?
    </p>
    <input type="date" [(ngModel)]="namcProvidedShipDate" />
    <small *ngIf="mandateDate.length > 0" class="text-danger">{{
      mandateDate
    }}</small>
    <br />
    <p>
      Are you sure you want to accept Supplier Ship Date for the selected
      order(s)?
    </p>
    <textarea
      placeholder="Enter any additional notes..."
      [(ngModel)]="namcComments"
    ></textarea>
  </div>
  <div class="dialog-actions">
    <button mat-button class="b2-button add yes-button" (click)="onValidateAndSave()">
      SAVE
    </button>
    <button mat-button class="no-button" (click)="closeDialog()">CANCEL</button>
  </div>
</div>
